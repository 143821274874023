<template>
  <v-card >
    <v-container >
      <h1>分析結果</h1>
      <hr/>
      <div style="position: relative;" v-for="resMsg in detail.results" :key="JSON.stringify(resMsg)">
        <h3>{{ resMsg.result.pose }}</h3>
        <table>
          <tr v-if="resMsg.result.pose === '立ち正面'">
            <!-- Front stand -->  
            <th>頭垂直</th>
            <th>上半身垂直</th>
            <th>下半身垂直</th>
            <th>目水平</th>
            <th>肩水平</th>
            <th>腰水平</th>
            <th>右手</th>
            <th>左手</th>
            <th>右膝屈曲</th>
            <th>左膝屈曲</th>
          </tr>
          <tr v-if="resMsg.result.pose === '立ち右側面'">
            <!-- right stand -->
            <th>頭垂直</th>
            <th>上半身垂直</th>
            <th>下半身垂直</th>
            <th>右上腕傾き</th>
            <th>右肘</th>
            <th>右膝屈曲</th>
          </tr>
          <tr v-if="resMsg.result.pose === '立ち左側面'">
            <!-- left stnad -->
            <th>頭垂直</th>
            <th>上半身垂直</th>
            <th>下半身垂直</th>
            <th>左上腕傾き</th>
            <th>左肘</th>
            <th>左膝屈曲</th>
          </tr>
          <tr v-if="resMsg.result.pose === '座り右側面'">
            <!-- right sitting -->
            <th>首</th>
            <th>上半身</th>
            <th>右上腕傾き</th>
            <th>右肘</th>
            <th>右股関節</th>
            <th>右膝</th>
            <th>右足</th>
          </tr>
          <tr v-if="resMsg.result.pose === '座り左側面'">
            <!-- sitting left -->
            <th>首</th>
            <th>上半身</th>
            <th>左上腕傾き</th>
            <th>左肘</th>
            <th>左股関節</th>
            <th>左膝</th>
            <th>左足</th>
          </tr>
          <tr v-if="resMsg.result.pose === '立ち正面'">

            <!-- stand front -->
            <!-- <th>頭垂直</th>
            <th>上半身垂直</th>
            <th>下半身垂直</th>
            <th>目水平</th>
            <th>肩水平</th>
            <th>腰水平</th>
            <th>右手</th>
            <th>左手</th>
            <th>右膝屈曲</th>
            <th>左膝屈曲</th> -->
            <td v-for="(angle, index) in resMsg.result.angles" :key="angle">

              {{ index === 0?((angle[0] === '-')?"左傾き":"右傾き"):index ===6?(angle[0] === '-')?"内側屈曲":"外側屈曲":index ===7?(angle[0] === '-')?"外側屈曲":"内側屈曲":index ===8?(angle[0] === '-')?"O脚":":X脚":index ===9?(angle[0] === '-')?"X脚":": O脚":(index ===1)?(angle[0] === '-')?"左傾き":"右傾き":(index === 4) ?((angle[0] === '-')?"左傾き":"右傾き"):(index === 3) ?((angle[0] === '-')?"左傾き":"右傾き"):(index === 5) ?((angle[0] === '-')?"左傾き":"右傾き"):(index === 2) ?( (angle[0] === '-')?"左傾き":"右傾き"):""
          }}
              {{angle}}°</td>
          </tr>
          <tr v-if="resMsg.result.pose === '立ち左側面'">
            <!-- stand left -->
            <td v-for="(angle, index) in resMsg.result.angles" :key="angle">
              {{ (index === 0|| index === 1 || index === 2)?
              ((angle[0] == '-')?"前傾":"後傾"):(index === 3)?((angle[0] == '-')?"後": '前'):(index === 4)?((angle[0] == '-')?"過伸展": '屈曲'):(index === 5)?((angle[0] == '-')?"過伸展": '屈曲'):""}}

              {{ angle }}°</td>
          </tr>
          <tr v-if="resMsg.result.pose === '立ち右側面'">
            <!-- stand Right -->
            <td v-for="(angle, index) in resMsg.result.angles" :key="angle">
              {{ (index === 0 ||index === 1 || index === 2)?
              ((angle[0] == '-')?"後傾":"前傾"):(index === 3)?((angle[0] == '-')?"前": '後'):(index ===4)?((angle[0] == '-')?"屈曲": '過伸展'):(index === 5)?((angle[0] == '-')?"屈曲": '過伸展'):""}}
              {{angle}}°</td>
          </tr>
          <tr v-if="resMsg.result.pose === '座り右側面'">
            <!-- sitting Right -->
            <td v-for="(angle, index) in resMsg.result.angles" :key="angle">
              {{ (index === 3 || index === 4)?((angle[0] == '-')?"屈曲":":過伸展"): (index === 0 || index === 1)?((angle[0] == '-')?"前傾":"後傾"):(index === 5 || index === 6)?((angle[0] == '-')?"過伸展":"屈曲"):(index === 2)?((angle[0] == '-')?"前":" 後"):""}}
              {{angle}}°</td>
          </tr>
          <tr v-if="resMsg.result.pose === '座り左側面'">
            <!-- sitting left -->
            <td v-for="(angle, index) in resMsg.result.angles" :key="angle">
              {{ (index === 4 || index === 6 || index === 3)?((angle[0] == '-')?"過伸展":"屈曲"): (index === 0 || index === 1)?((angle[0] == '-')?"後傾":"前傾"):(index === 5)?((angle[0] == '-')?"屈曲":" 過伸展"):(index === 2)?((angle[0] == '-')?"後":" 前"):""}}
              {{angle}}°</td>
          </tr>
        
        </table>
        <div class="container">
          <img :src="resMsg.result.figureBlobURL" alt="figure image error">
          <img :src="resMsg.result.imageBlobURL" alt="image error">
        </div>
        <div v-for="msg in resMsg.msgs" :key="msg">
          <p class="box">{{ msg.description }}</p>
          <img v-if="msg.condImg && !Array.isArray(msg.condImg) && msg.condImg !== []"
               class="center"
               :src="msg.condImg"
               alt="cond img error">
        </div>
        <v-btn @click="showCommentAll" depressed :ripple="false" class="white--text" text style="position: fixed; right: 40px;bottom: 40px; background-color: green; min-width: 100px;" >
          コメント 
        </v-btn>
      </div>
     
    </v-container>
       
  </v-card>
  
</template>

<script>
export default {
  props: ["detail",'replyAll','wait','showCommentAll'],
  name: "EmailPreview",
  mounted() {
    // console.log('this.detail', this.resMsg)
    // console.log('this.detail', this.replyAll)
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
}

table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.container {
  text-align: center;
}

p.box {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  text-align: left;
  padding: 4px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

img {
  margin: 5px 10px;
  padding: 5px;
  max-width: 50%;
}
</style>
