<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          small
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        詳細
      </v-btn>
    </template>

    <v-card style="display: flex;flex-direction: column;">
      <img style="width: 300px; align-self: center;" v-if="url !==''" :src="url"/>

      <v-card-text>
        <p>姿勢についての解説</p>
        <div style="font-size: 20px;letter-spacing: 1.1px;" class="text--primary">
          {{ description }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    description: String,
    url: String,
  },
  created() {
    console.log('url', this.url)
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>