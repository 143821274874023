<template>
  <div class="loader" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    console.log("isLoading",this.isLoading)
  },
  watch: {
    isLoading(newValue) {
      console.log('LoaderComponent: isLoading changed to', newValue);
    }
  }
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ccc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin .8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
