<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="conditions"
        class="elevation-1"
        :loading="loading"
        loading-text="読込中"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>評価項目</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                評価を追加
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                      v-if="editedIndex === -1"
                      v-model="editedItem.name"
                      label="評価名"
                  ></v-text-field>
                  <v-select
                      :items="['立ち正面','立ち左側面','立ち右側面','座り左側面','座り右側面']"
                      v-model="editedItem.direction"
                      label="方向"
                      v-on:input="generateRegex"
                  />
                  <v-text-field
                      v-model="editedItem.condition"
                      label="条件"
                      autocomplete="off"
                      :hint="hintMsg"
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.description"
                      label="説明"
                  ></v-text-field>
                  <v-file-input label="画像を選択" v-model="editedItem.condImg"/>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.condImg="{item}">
        <a v-if="typeof item.condImg === 'string' && item.condImg !== ''" :href="item.condImg">Link</a>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <Loader :isLoading="this.isSaving"></Loader>
  </v-container>
</template>

<script>
import {FRONT_POINTS, LEFT_POINTS, LEFT_SIT_POINTS, RIGHT_POINTS, RIGHT_SIT_POINTS} from '@/App'
import db from "@/firebase/firebaseInit";
import Loader from '../../loader.vue';

export default {
  name: "Condition",
  components:{Loader},
  data: () => ({
    hintMsg: '',
    dialog: false,
    dialogDelete: false,
    isSaving:false,
    points: [],
    regex: null,
    headers: [
      {
        text: '評価名',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: '条件', value: 'condition', sortable: false},
      {text: '方向', value: 'direction', sortable: false},
      {text: '説明', value: 'description', sortable: false},
      {text: '画像', value: 'condImg', sortable: false},
      {text: '編集', value: 'actions', sortable: false},
    ],
    conditions: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      condition: '',
      direction: '',
      description: '',
      condImg: []
    },
    defaultItem: {
      name: '',
      condition: '',
      direction: '',
      description: '',
      condImg: [],
    },
    loading: true
  }),
  async mounted() {
    await this.getData()
    this.loading = false
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '条件を追加' : '条件を編集'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    generateRegex(direction) {
      console.log('editedItem', this.editedItem)
      console.log('direction', direction)
      if (direction === '立ち正面') this.points = FRONT_POINTS
      if (direction === '立ち左側面') this.points = LEFT_POINTS
      if (direction === '立ち右側面') this.points = RIGHT_POINTS
      if (direction === '座り左側面') this.points = LEFT_SIT_POINTS
      if (direction === '座り右側面') this.points = RIGHT_SIT_POINTS
      this.points.sort((a, b) => {
        return b.length - a.length
      })
      this.hintMsg = '使用可能な文字(それぞれの文字の間に半角スペースを入れてください): '
      let pattern = '^('
      this.points.forEach(point => {
        this.hintMsg += point + ', '
        pattern += point + '|'
      })
      this.hintMsg += '+, -, ||, &&, =, ==, >, <, 0-9, ()'
      pattern += ' |\\+|-|>|<|&&|=|\\(|\\)|\\|\\||[0-9])+$'
      console.log('hintMsg', this.hintMsg)
      console.log('points', this.points)
      console.log('pattern', pattern)
      this.regex = new RegExp(pattern);
    },
    testCondition(condition) {
      const before = condition
      if (this.regex.test(condition) && condition.length !== 0) {
        this.points.forEach(point => {
          condition = condition.replaceAll(point, '-1')
        })
        try {
          eval(`
            if (${condition}) {
              console.log('${condition}' + '\\ncondition is true')
            } else {
              console.log('${condition}' + '\\ncondition is false')
            }
          `)
          return true;
        } catch (error) {
          alert('条件式が不正です\n' + before)
          return false
        }
      }
      alert('不正な文字列が入力されています\n' + before)
      return false
    },
    editItem(item) {
      this.editedIndex = this.conditions.indexOf(item)
      this.editedItem = {
        name: item.name.toString(),
        condition: item.condition.toString(),
        direction: item.direction.toString(),
        description: item.description.toString(),
        condImg: item.condImg,
      }
      this.dialog = true
      this.generateRegex(this.editedItem.direction)
    },
    deleteItem(item) {
      this.editedIndex = this.conditions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.isSaving = true
      db.collection('conditions').doc(this.editedItem.name).delete()
      this.conditions.splice(this.editedIndex, 1)
      this.closeDelete()
      this.isSaving = false
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      this.isSaving = true
      if (!this.testCondition(this.editedItem.condition)) return
      if (this.editedItem.condImg && this.editedItem.condImg instanceof File) {
        console.log('this.editedItem.condImg', this.editedItem.condImg)
        this.editedItem.condImg = await this.$store.dispatch("uploadCondImg", this.editedItem.condImg);
      } else {
        this.editedItem.condImg = ''
      }
      const database = db.collection('conditions').doc(this.editedItem.name)
      await database.set(this.editedItem);
      if (this.editedIndex > -1) {
        Object.assign(this.conditions[this.editedIndex], this.editedItem)
      } else {
        this.conditions.push(this.editedItem)
      }
      this.isSaving = false
      this.close()
    },
    async getData() {
      const dataBase = await db.collection('conditions').get();
      dataBase.docs.map(doc => this.conditions.push(doc.data()))
    },
  },
}
</script>

<style scoped>
</style>