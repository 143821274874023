
<template>
  <div class="carousel">
    <div class="carousel-inner">
      <VueSlickCarousel :arrows="true" :dots="false" :autoplay="true" :autoplaySpeed="3000" :speed="1000">
        <HomeSlider :images="image1" />
        <HomeSlider :images="image2" />
      </VueSlickCarousel>
    </div>
   
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import HomeSlider from "../views/HomeSlider.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  data() {
    return {
      items: [
        {
          image: "slide2.png",
          title: "Slide 2",
          description: "Nulla tristique lectus vel sagittis faucibus.",
        },
        {
          image: "slide3.png",
          title: "Slide 3",
          description:
            "Pellentesque nec nunc a eros congue congue vel eu arcu.",
        },
      ],
      currentIndex: 0,
      image1:
        "slide2.png",
      image2:
        "slide3.png",
     
    };
  },
  components: {
    HomeSlider,
    VueSlickCarousel
  },
  methods: {
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    prev() {
      this.currentIndex =
        (this.currentIndex + this.items.length - 1) % this.items.length;
    },
  },
};
</script>

<style>
.slieImage {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  /*   */
}
.container {
  display: block !important;
  padding-left: 2rem;
  padding-right: 2rem;
}
/*  */
</style>








