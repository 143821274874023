<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1"
        :loading="loading"
        loading-text="読込中"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Edit User: {{ editedItem.email }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                      v-model="editedItem.username"
                      label="Username"
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.schoolName"
                      label="SchoolName"
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.schoolCode"
                      label="SchoolCode"
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.manager"
                      label="Manager"
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.contact"
                      label="Contact"
                  ></v-text-field>
                  <v-switch
                  label="User enable/disable"
                  v-model="editedItem.disabled"
                  ></v-switch>
                  <div v-if="editedItem.reminderDate" style="display: flex; gap: 5px; align-items: center;">
                  <p style="font-weight: bold;">リマインダー :- </p>
                  <p>{{ editedItem.reminderDate }}</p>
                 </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.disabled="{ item }">

        <v-simple-checkbox
        @mouseover="console.log(item.disabled)"
        :disabled="true" :value="item.disabled" 
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <Loader :isLoading="this.isSaving"></Loader>
  </v-container>
  
</template>

<script>
import firebase from "firebase";
import db from "../firebase/firebaseInit";
import Loader from "../../loader.vue";
export default {
  name: "User",
  components:{Loader},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      isSaving:false,
      headers: [
        {text: 'ID', align: 'start', sortable: false, value: 'username'},
        {text: 'メールアドレス', value: 'email', sortable: false},
        {text: '学校名', value: 'schoolName', sortable: false},
        {text: '学校コード', value: 'schoolCode', sortable: false},
        {text: '担当者', value: 'manager', sortable: false},
        {text: '連絡先', value: 'contact', sortable: false},
        {text: '無効化', value: 'disabled', sortable: false},
        {text: '編集', value: 'actions', sortable: false},
      ],
      email: '',
      users: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      loading: true
    };
  },
  async mounted() {
    await this.getData()
    console.log('iam here ', this.users)
    this.loading = false
  },
  computed: {
  console: () => console,
  window: () => window,
  },
  methods: {
    editItem(item) {
      console.log("edititem", item)
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      console.log("edititem", item)
    },
    async deleteItemConfirm() {
      try {
      this.isSaving = true

        console.log("this.editedItem.username", this.editedItem)
        
        const deleted = await this.deleteUser(this.editedItem.email)
        db.collection('users').doc(this.editedItem.id).delete()
        console.log('deleted: ', deleted);
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
      this.isSaving = false
      } catch (error) {
        alert(error?.message)
        console.log('error: ', error);
        
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      this.isSaving = true
      console.log('isSaving: ', this.isSaving);
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
      } else {
        this.users.push(this.editedItem)
      }
      if (this.editedItem.disabled) {
        const msg = await this.disableUser(this.editedItem.email)
        console.log(msg)
      } else {
        const msg = await this.enableUser(this.editedItem.email)
        console.log(msg)
      }
      const database = db.collection('users').doc(this.editedItem.id)
      await database.set(this.editedItem);
      this.close()
      this.isSaving = false

    },
    async addAdmin() {
      const addAdmin = await firebase.functions().httpsCallable("addAdminRole");
      const result = await addAdmin({email: this.email});
      this.functionMsg = result.data.message;
    },
    async enableUser(email) {
      const enableUser = await firebase.functions().httpsCallable("enableUser");
      const result = await enableUser({email: email});
      this.functionMsg = result.data.message;
    },
    async delAdmin() {
      const delAdmin = await firebase.functions().httpsCallable("delAdminRole");
      const result = await delAdmin({email: this.email});
      this.functionMsg = result.data.message;
    },
    async disableUser(email) {
      const disableUser = await firebase.functions().httpsCallable("disableUser");
      const result = await disableUser({email: email});
      this.functionMsg = result.data.message;
    },
    async deleteUser(email) {
      const deleteUser = await firebase.functions().httpsCallable("deleteUser");
      console.log('deleteUser: ',deleteUser);
      const result = await deleteUser({email: email});
      console.log('result: ', result);
      // this.functionMsg = result.data.message;
    },
    async getData() {
      const listAllUser =  firebase.functions().httpsCallable("listAllUsers");
      // console.log("🚀 ~ file: User.vue:211 ~ getData ~ result", await listAllUsers())
      const result = await listAllUser();
      const database = await db.collection('users').get();
      console.log("database", database)
      database.docs.map(doc => {
        const data = doc.data()
        console.log('data: ' + JSON.stringify(data))
        console.log('result.data: ' + JSON.stringify(result))
        data['id'] = doc.uid
        result.data.find(user => {
          if (user.email === data.email) data['disabled'] = user.disabled
        })
        this.users.push({...data, id:doc.id})
        console.log(this.users)
      })
    },
  },
}
</script>

<style scoped>
</style>
