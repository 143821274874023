import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {

    // apiKey: "AIzaSyCODQWbHIn63q49WReWofvbpTBzbVqTDUc",
    // authDomain: "functionscloud-7db10.firebaseapp.com",
    // projectId: "functionscloud-7db10",
    // storageBucket: "functionscloud-7db10.appspot.com",
    // messagingSenderId: "838906786209",
    // appId: "1:838906786209:web:28727e56522ac7ede11be0",
    // measurementId: "G-TDYNFZQ9GT"
    apiKey: "AIzaSyCIgxjfAvPZvExyItbBc62wIu1Wgk7hruk",
    authDomain: "poseschool-dcb85.firebaseapp.com",
    projectId: "poseschool-dcb85",//"poseschool-dcb85",
    storageBucket: "poseschool-dcb85.appspot.com",
    messagingSenderId: "300770874752",
    appId: "1:300770874752:web:19cce2b53781ae939fdd65",
    measurementId: "G-JDGZWKZN0M"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);



const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {timestamp};
// if (location.hostname === "localhost" || location.hostname === "222.229.5.132" || location.hostname === "shisei.ddns.net" || location.hostname === "192.168.0.205" || location.hostname ==="poseschool.line-fitness.net") {
//     const db = firebaseApp.firestore()
//     db.useEmulator(location.hostname, 8080);
//     firebase.functions().useEmulator(location.hostname, 5001)
//     const auth = firebase.auth();
//     auth.useEmulator("http://" + location.hostname + ":9099");
//     const storage = firebase.storage();
//     storage.useEmulator('localhost', 9199);
// }
export default firebaseApp.firestore();
