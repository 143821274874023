import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/auth";
import moment from "moment";

Vue.config.productionTip = false;
Vue.config.devtools = true;
let app;
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            vuetify,
            router,
            store,
            render: (h) => h(App),
        }).$mount("#app");
    }
});

moment.locale("ja", {
    weekdays: [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
    ],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});
// In your main.js or App.vue

// Add event listener
window.addEventListener('beforeunload', () => {
    localStorage.removeItem('isComponentCreated');
    // Add more keys if needed
  });
  
