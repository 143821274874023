<template>
  <v-container>
    <v-stepper v-model="page">
      <v-stepper-header>
        <v-stepper-step :complete="page > 1" step="1">
          画像アップロードの説明
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="page > 2" step="2">
          必要情報・画像ファイルの入力
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          入力内容の確認
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Explain></Explain>

          <v-row>
            <v-col offset-sm="4" sm="4" cols="12">
              <v-btn class="nextStepButton" block color="primary" @click="page = 2">
                次へ進む
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <UploadForm v-bind:form="form"></UploadForm>

          <v-row>
            <v-col offset-sm="4" sm="4" cols="12">
              <v-btn block color="primary" @click="page = 3">
                確認画面へ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <Confirm v-bind:form="form"></Confirm>

          <v-row>
            <v-col offset-sm="4" sm="4" cols="12">
              <v-btn block color="primary" @click="upload">
                入力完了
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import UploadForm from "@/components/UploadForm.vue";
import Confirm from "@/components/Confirm.vue";
import Explain from "@/components/Explain.vue";
export default {
  components: {
    UploadForm,
    Explain,
    Confirm,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    form() {
      return this.$store.state.form;
    },
  },
  methods: {
    upload() {
      this.$router.push({ name: "Result" });
    },
  },
  
};
</script>

<style scope>

.nextStepButton{
  margin-top: -5px !important;
}
.v-dialog{
  box-shadow: unset !important;
  /* width: 100% !important; */
}
</style>

