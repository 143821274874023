<template>
  <v-container>
    <v-select :items="statusSelect" label="状態" solo></v-select>
    <v-data-table
      :headers="headers"
      :items="poseInfos"
      :items-per-page="5"
      show-select
      class="elevation-1"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)" dark>
          {{ item.status | statusName }}
        </v-chip>
      </template>

      <template v-slot:[`item.uploaded`]="{ item }">
        {{ item.uploaded | moment }}
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <v-btn color="primary" dark :href="`/pose/${item.id}`">
          詳細
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      statusSelect: ["送信済み", "未送信", "却下", "すべて"],
      headers: [
        { text: "状態", value: "status" },
        { text: "番号", value: "number" },
        { text: "アップロード日", value: "uploaded" },
        { text: "画像", value: "images" },
        { text: "詳細", value: "id" },
      ],
      poseInfos: [
        {
          id: 10,
          status: 1,
          number: 1111,
          uploaded: new Date(2021, 1, 1),
          images: "立ち正面",
        },
        {
          id: 11,
          status: 2,
          number: 2222,
          uploaded: new Date(2021, 2, 1),
          images: "立ち正面 座り左側面",
        },
        {
          id: 12,
          status: 3,
          number: 3333,
          uploaded: new Date(2021, 3, 1),
          images: "立ち正面",
        },
      ],
    };
  },
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY/MM/DD(ddd)");
    },
    statusName: function(status) {
      if (status === 1) return "却下";
      else if (status === 2) return "返信済み";
      else if (status === 3) return "未送信";
    },
  },
  methods: {
    getColor(status) {
      if (status === 1) return "red";
      else if (status === 2) return "green";
      else if (status === 3) return "orange";
    },
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>
