<template>
  <v-container>
    <v-card class="mx-auto" max-width="700">
      <v-card-title>入力内容の確認</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  項目
                </th>
                <th class="text-left">
                  入力内容
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>番号4桁(半角英数字)</td>
                <td >{{ form.number }}</td>
              </tr>
              <tr>
                <td>お子様の身長</td>
                <td>{{ form.height }}</td>
              </tr>
              <tr>
                <td>Eメールアドレス</td>
                <td>{{ form.email }}</td>
              </tr>
              <tr>
                <td>立ち正面写真</td>
                <td v-if="form.frontStandingImg">{{ form.frontStandingImg.name }}</td>
              </tr>
              <tr>
                <td>立ち右側面写真</td>
                <td v-if="form.rightSideStandingImg">{{ form.rightSideStandingImg.name }}</td>
              </tr>
              <tr>
                <td>立ち左側面写真</td>
                <td v-if="form.leftSideStandingImg">{{ form.leftSideStandingImg.name }}</td>
              </tr>
               <tr>
                <td>座り右側面写真</td>
                <td v-if="form.rightSideSittingImg">{{ form.rightSideSittingImg.name }}</td>
              </tr>
              <tr>
                <td>座り左側面写真</td>
                <td v-if="form.leftSideSittingImg">{{ form.leftSideSittingImg.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    form: {
      number: String,
      email: String,
      height: String,
      frontStandingImg: File,
      sideStandingImg: File,
      sideSittingImg: File,
    },
  },
};
</script>

<style></style>
