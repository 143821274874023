<template>
  <div class="form-wrap">
    <form class="register">
      <h2>利用者登録</h2>
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="学校コード" v-model="schoolCode"/>
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="メールアドレス" v-model="email"/>
          <email class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="学校名" v-model="schoolName"/>
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="担当者" v-model="manager"/>
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="連絡先" v-model="contact"/>
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="ID" v-model="username"/>
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="password" placeholder="パスワード" v-model="password"/>
          <password class="icon"/>
        </div>
        <div class="input">
          <input type="date" placeholder="時間リマインダー" v-model="reminderDate"/>
          <date class="icon"/>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <button style="padding: 5px 10px; border: 1px solid black; border-radius: 5px; min-width: 100px;" @click.prevent="register">Sign Up</button>
<!--      <div class="angle"></div>-->
    </form>
    <div class="background"></div>
    <Loader :isLoading="this.isSaving"></Loader>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import user from "../assets/Icons/user-alt-light.svg";
import date from "../assets/Icons/date-icon.svg";
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";
import Loader from "../../loader.vue";

export default {
  name: "Register",
  components: {
    email,
    password,
    user,
    date,
    Loader
  },
  data() {
    return {
      schoolCode: "",
      email: "",
      schoolName: "",
      manager: "",
      contact: "",
      username: "",
      password: "",
      error: null,
      errorMsg: "",
      reminderDate:"",
      isSaving:false
    };
  },
  mounted(){
  },
  updated(){
    console.log('here',this.reminderDate)

  },
  methods: {
    async register() {
      if (
          this.schoolCode !== "" &&
          this.email !== "" &&
          this.schoolName !== "" &&
          this.manager !== "" &&
          this.contact !== "" &&
          this.username !== "" &&
          this.password !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        
        try {
          this.isSaving = true
          const currentAuth = await firebase.auth().currentUser;
        const firebaseAuth = await firebase.auth();
       
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
        console.log('createUser: ', createUser);
        const result = await createUser;
        const dataBase = db.collection("users").doc(result.user.uid);
        console.log("🚀 ~ file: Register.vue:91 ~ register ~ dataBase", dataBase)
        await firebase.auth().updateCurrentUser(currentAuth)
        await dataBase.set({
          schoolCode: this.schoolCode,
          email: this.email,
          schoolName: this.schoolName,
          manager: this.manager,
          contact: this.contact,
          username: this.username,
          enabled: false,
          reminderDate:this.reminderDate
        });
        await this.$router.push({name: "Users"});
        this.isSaving = false
        return;
        } catch (error) {
          if(error.message === "The email address is already in use by another account.")
          {
            this.error = true;
            this.errorMsg = error.message
            return
            
          }
          this.isSaving = false
          console.log('error: ', error.message);
          
        }
      }
      else
      {
        this.error = true;
         this.errorMsg = "Please fill out all the fields!";
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  h2 {
    max-width: 350px;
  }
}
</style>
