import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import db from "../firebase/firebaseInit";
import {v4 as uuidv4} from "uuid";

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        user: null,
        form: {
            number: "",
            email: "",
            height: 0,
            frontStandingImg: null,
            rightSideStandingImg: null,
            leftSideStandingImg: null,
            rightSideSittingImg: null,
            leftSideSittingImg: null,
        },
        analyzeData: {},
        refs: {
            frontStandingImgRef: null,
            rightSideStandingImgRef: null,
            leftSideStandingImgRef: null,
            rightSideSittingImgRef: null,
            leftSideSittingImgRef: null,
        }
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {},
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        updateUser(state, payload) {
            state.user = payload;
        },
        setAnalyzeData(state, {poseDirection, angles}) {
            Vue.set(state.analyzeData, poseDirection, angles);
        },
        save(state) {
            // Json文字列に変換しLocalStorageへ保存
            localStorage.setItem("store", JSON.stringify(state));
        },
        load(state) {
            if (localStorage.getItem("store")) {
                // LocalStorageから取得したJson文字列をパース
                const store = JSON.parse(localStorage.getItem("store"));
                // stateを置き換えます。
                this.replaceState(Object.assign(state, store));
            }
        },
    },
    actions: {
        resetState({commit}) {
            commit('resetState')
        },
        // eslint-disable-next-line no-unused-vars
        async getCurrentUser({commit}, user) {
            const token = await user.getIdTokenResult();
            console.log("token: " + JSON.stringify(token));
            const admin = await token.claims.admin;
            console.log("admin: " + admin);
        },
        // eslint-disable-next-line no-unused-vars
        async uploadCondImg({commit}, condImg) {
            const storageRef = firebase.storage().ref();
            const generateRef = (imgFile) => {
                if (!(imgFile instanceof File)) {
                    return null;
                }
                return storageRef.child(`${uuidv4()}.${imgFile.name.match(/[^.]+$/)}`);
            };
            const condImgRef = generateRef(condImg);
            let url;
            try {
                await condImgRef.put(condImg);
                url = await condImgRef.getDownloadURL();
            } catch (e) {
                alert(e.message);
                return;
            }
            return url;
        },
        // eslint-disable-next-line no-unused-vars
        async uploadImage({commit}, img) {
            console.log('imageBlob', img)
            const storageRef = firebase.storage().ref();
            const generateRef = (imgFile) => {
                if (!(imgFile instanceof File)) {
                    return null;
                }
                return storageRef.child(`${uuidv4()}.${imgFile.name.match(/[^.]+$/)}`);
            };
            const imgRef = generateRef(img)
            try {
                await imgRef.put(img)
                return await imgRef.getDownloadURL()
            } catch (e) {
                console.log(e.message)
                alert("画像のアップロードに失敗しました")
            }
        },
        // 姿勢画像とメール情報などをアップロード
        // TODO: don't upload data if some info is missing
        // eslint-disable-next-line no-unused-vars
        async uploadPoseData({commit}, poseData) {
            if (!poseData['email'] || !poseData['height'] || !poseData['number']) {
                console.log('invalid pose data', poseData)
                return
            }
            const storageRef = firebase.storage().ref();
            const generateRef = (imgFile) => {
                if (!(imgFile instanceof File)) {
                    return null;
                }
                return storageRef.child(`${uuidv4()}.${imgFile.name.match(/[^.]+$/)}`);
            };

            const images = [
                {
                    title: "frontStandingImg",
                    img: poseData.frontStandingImg,
                }, {
                    title: "rightSideStandingImg",
                    img: poseData.rightSideStandingImg,
                },
                {
                    title: "leftSideStandingImg",
                    img: poseData.leftSideStandingImg,
                },
                {
                    title: "rightSideSittingImg",
                    img: poseData.rightSideSittingImg,
                },
                {
                    title: "leftSideSittingImg",
                    img: poseData.leftSideSittingImg,
                }
            ]

            /* eslint-disable no-unused-vars */
            const {
                ['frontStandingImg']: a,
                ['leftSideSittingImg']: b,
                ['rightSideSittingImg']: c,
                ['leftSideStandingImg']: d,
                ['rightSideStandingImg']: e,
                ...poseDataWithFilePath
            } = poseData

            for (let imgObj of images) {
                if (imgObj.img == null) {
                    continue
                }
                const imgRef = generateRef(imgObj.img)
                try {
                    console.log('imgObj.img', imgObj.img)
                    await imgRef.put(imgObj.img)
                    poseDataWithFilePath[imgObj.title] = await imgRef.getDownloadURL()
                } catch (e) {
                    console.log(e.message)
                    alert("画像のアップロードに失敗しました")
                    return
                }
            }

            poseDataWithFilePath['createdAt'] = firebase.firestore.FieldValue.serverTimestamp()

            const uid = this.state.user.uid
            const dataBase = await db.collection("users").doc(uid).collection("poseData")
            await dataBase.add(poseDataWithFilePath).catch((error) => {
                console.error(error);
                alert("アップロードに失敗しました");
            });
        },
        doSave({commit}) {
            commit("save");
        },
        doLoad({commit}) {
            commit("load");
        },
    },
    modules: {},
});
