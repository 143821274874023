<template>
  <v-container>
    <h1>しせいの学校管理者画面</h1>
    <p>学校コード XXXX</p>
    <PoseInfoTable></PoseInfoTable>
  </v-container>
</template>

<script>
import PoseInfoTable from "@/components/PoseInfoTable.vue";
export default {
  components: { PoseInfoTable },
};
</script>

<style></style>
