<template>
  <div>
    <p>
      正面、右側面、左側面の画像を下記のURLからアップロードしていただけますか。
    </p>
    <v-container class="slieImage">
      <img style="height: 440px; width: 100%" :src="images">
      <p>画像は正面、右側面、左側面の3種類をアップロードしてください</p>
    </v-container>
  </div>
</template>

<script>

import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "HomeSlider",
  props: ["images"],
  data() {
    return {
      image1:
        "https://www.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg",
      image2:
        "https://www.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg",
    };
  },
    async mounted() {
    console.log('data', this.images)
   
  },
  
};
</script>

<style>
</style>