<template>
  <v-chip color="red" label small text-color="white">
    必須
  </v-chip>
</template>

<script>
export default {
  name: "RequiredBadge",
};
</script>

<style></style>
