<template>
  <div>
    <!--    <PoseCanvas :conditions="[]" :image-src="require('@/assets/front.jpg')" direction="front" pose="standing"/>-->
    <PoseCanvas :conditions="[]" :image-src="require('@/assets/front.jpg')"
                :poseDirectionData="frontStanding"/>
    <!--    <RotateDialog @imageUpdate="updateImage($event)" :image-src="require('@/assets/front.jpg')"></RotateDialog>-->
    <!--    <v-btn @click="test"></v-btn>-->

  </div>
</template>

<script>
import PoseCanvas from "@/components/PoseCanvas";
import {PoseDirectionData} from "@/views/PoseResult";
// import RotateDialog from "@/components/RotateDialog";
// import {cos} from "@tensorflow/tfjs";

export default {
  name: 'Home',
  components: {
    // RotateDialog,
    PoseCanvas
  },
  data() {
    return {
      frontStanding: new PoseDirectionData("frontStanding", "立ち正面", "standing", "front"),
      progress: 0
    }
  },
  methods: {
    images(file) {
      return URL.createObjectURL(file);
    },
    test() {
      console.log(this.$store.state.user.uid)
      // this.progress++
      // console.log('progress!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!:', this.progress)
    },
    updateImage(blob) {
      console.log(blob)
    },
  }
}
</script>

<style scoped>
</style>
