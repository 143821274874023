<template>
  <v-dialog
    eager
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-account-convert</v-icon>
    </template>
    <v-card flat>
      <v-toolbar>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>角度調節</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn class="logout-button" text @click="save">保存</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div style="background-color: #fff; height: 30px;width: 50%;position:sticky;top:0;z-index: 100;">
        <v-slider
        min="-180"
        max="180"
        v-model="degree"
        style="width: 100%;  "
      ></v-slider>
      </div>
      <button class="button" style="margin: 20px" v-on:click="addGrid">
        グリッド追加
      </button>
      <button class="button" style="margin: 20px" v-on:click="removeGrid">
        グリッド削除
      </button>

      <div class="containerModel">
        <button class="button" style="margin: 40px" v-on:click="dragLeft">
          左
        </button>

        <div
          style="
            position: relative;
            text-align: center;
            background-color: #ccc;
            height: auto;
            width: 800px;
          "
        >
          <div class="gridClass" v-if="gridStatus == true"  >
            <div class="gridLine" v-for="(i, index) in gridArray" :key="index" :style="`left:${index*57}px`"></div>
          
          </div>
          <div class="gridClass" v-if="gridStatus == true"  >
            <div class="gridLineH" v-for="(i, index) in gridArray" :key="index" :style="`top:${index*57}px`"></div>
          
          </div>
          <canvas ref="canvas" style="max-height: 100%; max-width: 100%" />
          <!--          <hr color="red" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 100%;">-->
          <hr
            color="red"
            style="
              position: absolute;

              margin: auto;
              width: 2px;
              height: 100%;
              display: inline-block;
            "
            :style="{ left: left + '%' }"
          />
        </div>
        <button class="button" style="margin: 20px" v-on:click="dragRight">
          右
        </button>
      </div>
      <!--      <hr style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto">-->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["imageSrc"],
  data() {
    return {
      dialog: false,
      max: 0,
      image: null,
      degree: 0,
      ctx: null,
      left: 50,
      gridStatus: false,
      gridArray:[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
    };
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext("2d");
    if (this.imageSrc) {
      this.image = new Image();
      this.image.src = this.imageSrc;
      this.image.onload = () => {
        const max = Math.sqrt(this.image.height ** 2 + this.image.width ** 2);
        console.log(max, max, max);
        this.$refs.canvas.height = max;
        this.$refs.canvas.width = max;
        this.ctx.translate(max / 2, max / 2);
        this.ctx.drawImage(
          this.image,
          -(this.image.width / 2),
          -(this.image.height / 2)
        );
        this.ctx.translate(-max / 2, -max / 2);
      };
    }
  },
  methods: {
    dragLeft() {
      this.left = this.left - 0.5;
    },
    dragRight() {
      this.left = this.left + 0.5;
    },
    async save() {
      
      this.dialog = false;
      const blob = await new Promise((resolve) =>
        this.$refs.canvas.toBlob(resolve)
      );
      this.$emit("imageUpdate", blob);
    },
    removeGrid() {
      this.gridStatus = false;
      const max = Math.sqrt(this.image.height ** 2 + this.image.width ** 2);
      this.ctx.clearRect(0, 0, max, max);

      this.ctx.translate(max / 2, max / 2);

      this.ctx.rotate((0 * Math.PI) / 180);
      this.ctx.drawImage(
        this.image,
        -(this.image.width / 2),
        -(this.image.height / 2)
      );
      this.ctx.rotate((-0 * Math.PI) / 180);
      this.ctx.translate(-max / 2, -max / 2);
    },
    addGrid() {
      this.gridStatus = true;
      // const max = Math.sqrt(this.image.height ** 2 + this.image.width ** 2);
      // this.ctx.clearRect(0, 0, max, max);

      // this.ctx.translate(max / 2, max / 2);

      // this.ctx.rotate((0 * Math.PI) / 180);
      // this.ctx.drawImage(
      //   this.image,
      //   -(this.image.width / 2),
      //   -(this.image.height / 2)
      // );
      // this.ctx.rotate((-0 * Math.PI) / 180);
      // this.ctx.translate(-max / 2, -max / 2);
      // for (let x = 0; x < this.$refs.canvas.width; x += 50) {
      //   this.ctx.moveTo(x, 0);
      //   this.ctx.lineTo(x, this.$refs.canvas.height);
      // }
      // for (let y = 0; y < this.$refs.canvas.height; y += 50) {
      //   this.ctx.moveTo(0, y);
      //   this.ctx.lineTo(this.$refs.canvas.width, y);
      // }
      // this.ctx.stroke();
    },
  },
  watch: {
    degree: function (n) {
     
      console.log("🚀 ~ file: RotateDialog.vue:111 ~ n:", n);
      const max = Math.sqrt(this.image.height ** 2 + this.image.width ** 2);
      this.ctx.clearRect(0, 0, max, max);

      this.ctx.translate(max / 2, max / 2);

      this.ctx.rotate((n * Math.PI) / 180);
      this.ctx.drawImage(
        this.image,
        -(this.image.width / 2),
        -(this.image.height / 2)
      );
      this.ctx.rotate((-n * Math.PI) / 180);
      this.ctx.translate(-max / 2, -max / 2);
      // if (this.gridStatus === true) {
      //   for (let x = 0; x < this.$refs.canvas.width; x += 50) {
      //     this.ctx.moveTo(x, 0);
      //     this.ctx.lineTo(x, this.$refs.canvas.height);
      //   }
      //   for (let y = 0; y < this.$refs.canvas.height; y += 50) {
      //     this.ctx.moveTo(0, y);
      //     this.ctx.lineTo(this.$refs.canvas.width, y);
      //   }
      //   this.ctx.stroke();
      // }
    },
    imageSrc: function (n) {
      this.image = new Image();
      this.image.onload = () => {
        const max = Math.sqrt(this.image.height ** 2 + this.image.width ** 2);
        this.$refs.canvas.height = max;
        this.$refs.canvas.width = max;
        this.ctx.translate(max / 2, max / 2);
        this.ctx.drawImage(
          this.image,
          -(this.image.width / 2),
          -(this.image.height / 2)
        );
        this.ctx.translate(-max / 2, -max / 2);
      };
      this.image.src = n;
    },
  },
  name: "RotateDialog",
};
</script>

<style scoped>
.gridLine {
  z-index: 400;
  /* left: 50px; */
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: black;
}
.gridLineH{
    z-index: 400;
  /* left: 50px; */
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
}
.gridClass {
  position: absolute;
  height: 100%;
  width: 100%;
}
.containerModel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

.logout-button {
  margin-top: 7px;
  height: 50px !important;
  width: 140px;
  display: inline-block;
  padding: 0px 20px;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.logout-button:hover {
  background-color: #c82333;
}


</style>
