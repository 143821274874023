<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">学校コード ▼</v-btn>
            <!--            <v-select filled :items="users" v-bind="attrs" v-on="on"></v-select>-->
          </template>
          <v-list>
            <v-list-item v-for="(item, listItemIndex) in users" :key="listItemIndex" @click="getPoses(item)">
              <v-list-item-title>{{ item.schoolCode }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider/>
            <v-tab v-for="vTabItem in vTabItems" :key="vTabItem.text" :style="'color:' + vTabItem.color + ';'">
              {{ vTabItem.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-dialog v-model="dialogDetail"  @input="close"  >
        <EmailPreview  :detail="this.detail" :replyAll="replyAll" :showCommentAll="showCommentAll"></EmailPreview>
      </v-dialog>
      <v-dialog  @click="closeCommentAll" class="dialoge" width='500'   v-model="dialogCommentAll">
        <v-card
            class="mx-auto "
            outlined
            
           
        >
          <v-card-title>メッセージ一斉送信</v-card-title>
          <v-card-text>
            <v-file-input accept="image/*" multiple v-model="commentImages"></v-file-input>
          </v-card-text>
            <v-card-title>メッセージ</v-card-title>
            <v-card-text>
            <v-textarea class="text-area" v-model="commentText"></v-textarea>
            </v-card-text>
          <v-card-actions>
            <v-btn
                text
                color="primary"
                @click="commentAll()"
            >
              送信
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="poses.filter(pose => pose.status === 'sent')"
              class="elevation-1"
              item-key="image[0].link"
              show-select
              :loading="loading"
              loading-text="読込中"
          >
            <template v-slot:item.status="">
              <v-btn depressed :ripple="false" color="green" class="white--text">返信済
              </v-btn>
            </template>
            <template v-slot:item.image="{item}">
              <a v-for="img in item.image" :key="img.pose" :href="img.link">{{ img.pose }},</a>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn @click="showDetail(item)" outlined color="primary">詳細</v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="poses.filter(pose => pose.status === 'unsent')"
              class="elevation-1"
              item-key="image[0].link"
              show-select
              :loading="loading"
              loading-text="読込中"
          >
            <template v-slot:item.status="">
              <v-btn depressed :ripple="false" color="orange" class="white--text">未返信
              </v-btn>
            </template>
            <template v-slot:item.image="{item}">
              <a v-for="img in item.image" :key="img.pose" :href="img.link">{{ img.pose }},</a>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn @click="showDetail(item)" outlined color="primary">詳細</v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="poses.filter(pose => pose.status === 'rejected')"
              class="elevation-1"
              item-key="image[0].link"
              show-select
              :loading="loading"
              loading-text="読込中"
          >
            <template v-slot:item.status="">
              <v-btn depressed :ripple="false" color="red" class="white--text">
                &nbsp;却&nbsp;下&nbsp;
              </v-btn>
            </template>
            <template v-slot:item.image="{item}">
              <a v-for="img in item.image" :key="img.pose" :href="img.link">{{ img.pose }},</a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn @click="showDetail(item)" outlined color="primary">詳細</v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="poses"
              class="elevation-1"
              item-key="image[0].link"
              show-select
              :loading="loading"
              loading-text="読込中"
          >
            <template v-slot:item.status="{item}">
              <v-btn depressed v-if="item.status === 'sent'" :ripple="false" color="green" class="white--text">返信済
              </v-btn>
              <v-btn depressed v-if="item.status === 'unsent'" :ripple="false" color="orange" class="white--text">未返信
              </v-btn>
              <v-btn depressed v-if="item.status === 'rejected'" :ripple="false" color="red" class="white--text">
                &nbsp;却&nbsp;下&nbsp;
              </v-btn>
            </template>
            <template v-slot:item.image="{item}">
              <a v-for="img in item.image" :key="img.pose" :href="img.link">{{ img.pose }},</a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn @click="showDetail(item)" outlined color="primary">詳細</v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn color="primary" text @click="replyAll">
          まとめて返信
        </v-btn>
        <v-btn color="primary" text @click="denyAll">
          まとめて却下
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" text @click="showCommentAll">
          一斉コメント
        </v-btn>
      </v-card-actions>
    </v-card>
    <Loader :isLoading="this.isSaving"></Loader>
  </v-container>

</template>

<script>
import firebase from "firebase";
import db from "@/firebase/firebaseInit";
import EmailPreview from "@/components/EmailPreview";
import Loader from "../../loader.vue";

export default {
  name: "Admin",
  components: {EmailPreview,Loader},
  data() {
    return {
      user: {},
      poses: [],
      loading: true,
      selected: [],
      isSaving:false,
      tab: null,
      users: [],
      srcdoc: [],
      vTabItems: [
        {text: '返信済み', color: 'green'},
        {text: '未返信', color: 'orange'},
        {text: '却下', color: 'red'},
        {text: 'すべて', color: 'black'},
      ],
      dialogDetail: false,
      dialogCommentAll: false,
      commentImages: [],
      commentText: '',
      detail: {},
      headers: [
        {text: '状態', value: 'status'},
        {text: '番号', value: 'number'},
        {text: 'アップ日', value: 'createdAt'},
        {text: '画像', value: 'image', sortable: false},
        {text: '詳細', value: 'actions', sortable: false},
      ],
      results: [],
    }
  },
  async mounted() {
    await this.getData()
    this.loading = false
  },
  methods: {
    showCommentAll() {
      this.dialogCommentAll = true
    },
    showDetail(item) {
      console.log('item: ', item);
      this.detail = {'email': item.email, 'results': item.results}
      this.dialogDetail = true
      this.selected=[item]
    },
    close() {
      this.dialogDetail = false
      this.selected=[]
    },
    closeCommentAll() {
      this.dialogCommentAll = false
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    async replyAll() {
      this.isSaving=true
      const mailDataP = Array.from(this.selected, async item => {
        console.log('item', item)
        return {'email': item.email, 'results': item.results}
      })
      const mailData = await Promise.all(mailDataP)
      console.log('mailData', mailData)
      const sendAdminMail = await firebase.functions().httpsCallable("sendAdminMail");
      await sendAdminMail({'mailData': mailData})
      console.log('this.user', this.user)
      const promises = Array.from(this.selected, item => {
        db.collection('users').doc(this.user.id).collection('poseData').doc(item.id).update({status: 'sent'})
      })
      Promise.all(promises).then(results => console.log(results)).catch(err => console.log(err))
      this.selected.forEach(item => {
        item.status = 'sent'
      })
      this.close()
      this.isSaving=false
      alert("Email sent successfully")
    },
    denyAll() {
      const promises = Array.from(this.selected, item => {
        db.collection('users').doc(this.user.id).collection('poseData').doc(item.id).update({status: 'rejected'})
      })
      Promise.all(promises).then(results => console.log(results)).catch(err => console.log(err))
      this.selected.forEach(item => {
        item.status = 'rejected'
      })
      console.log('denyAll')
    },
    async commentAll() {
      this.isSaving=true
      const commentImageURLsP = Array.from(this.commentImages, async commentImage => {
        return this.$store.dispatch("uploadCondImg", commentImage);
      })
      const commentImageURLs = await Promise.all(commentImageURLsP)
      const mailDataP = Array.from(this.selected, async item => {
        return {'email': item.email, 'images': commentImageURLs, 'text': this.commentText,"comment":true}
      })
      const mailData = await Promise.all(mailDataP)
      console.log(mailData)
      const sendAdminMail = await firebase.functions().httpsCallable("sendAdminMail");
      await sendAdminMail({'mailData': mailData})
      console.log(this.commentText)
      console.log(this.commentImages)
      console.log('commentAll')
      this.closeCommentAll()
      this.isSaving=false
      alert("Email sent successfully")
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
            this.padTo2Digits(date.getSeconds()),
          ].join(':')
      );
    },
    async getPoses(user) {
      const pCollection = await db.collection('users').doc(user.id).collection('poseData').get();
      const poses = []
      pCollection.docs.map(doc => {
        console.log('doc.data()', doc.data())
        let image = []

        image.push({dp: 'front_standing', pose: '立ち正面', link: doc.data().frontStandingImg})
        image.push({dp: 'right_side_standing', pose: '立ち右側面', link: doc.data().rightSideStandingImg})
        image.push({dp: 'left_side_standing', pose: '立ち左側面', link: doc.data().leftSideStandingImg})
        image.push({dp: 'right_side_sitting', pose: '座り右側面', link: doc.data().rightSideSittingImg})
        image.push({dp: 'left_side_sitting', pose: '座り左側面', link: doc.data().leftSideSittingImg})
        image = image.filter(element => {
          return element.link !== undefined;
        });

        let results = Object.values(doc.data()).filter(v => v.result !== undefined && v.result.pose !== undefined)

        poses.push({
          'createdAt': this.formatDate(doc.data()['createdAt'].toDate()),
          'status': doc.data().status ? doc.data().status : 'unsent',
          'number': doc.data().number,
          'email': doc.data().email,
          'image': image,
          'results': results,
          'id': doc.id
        })
      })
      this.poses = poses
      this.user = user
      console.log('this.poses', this.poses[0])
    },
    async getData() {
      const currentUser = await firebase.auth().currentUser
      console.log("userid ", currentUser)
      const listAllUsers = await firebase.functions().httpsCallable("listAllUsers");
      const result = await listAllUsers();
      const database = await db.collection('users').get();
      // console.log("🚀 ~ file: Admin.vue:333 ~ getData ~ database", database)
      // console.log("🚀 ~ file: Admin.vue:333 ~ getData ~ await db.collection('users')", await db.collection('users').get())
      database.docs.map(doc => {
        const data = doc.data()
        console.log('data: ' + JSON.stringify(data))
        console.log('result.data: ' + JSON.stringify(result))
        data['id'] = doc.id
        result.data.find(user => {
          if (user.email === data.email) data['disabled'] = user.disabled
        })
        this.users.push(data)
      })
      this.user = this.users[0]
      await this.getPoses(this.user)
      console.log(this.users)
    },
  },
}
</script>

<style scope>
.dialoge{
  box-shadow: unset !important;
  max-width: 50% !important;
  width: 50% !important;
}
textarea{
  height: 200px !important;
  max-height: 300px;
  resize: none !important;
  
}
textarea::-webkit-scrollbar{
  display: none;
}
</style>