<template>
  <v-app>
    <v-main>
      <v-app-bar v-if="!navigation">
        <v-app-bar-nav-icon v-if="admin" @click="drawer = true"/>
        <v-toolbar-title style="cursor: pointer" @click="$router.push('/')">しせいの学校 オンライン</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <button class="logout-button" @click="logout">ログアウト</button>
        </v-toolbar-title>
      </v-app-bar>
      <v-navigation-drawer
      style="z-index:500"
          v-model="drawer"
          absolute
          temporary
      >
        <v-list-item :to="'/'">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              しせいの学校
            </v-list-item-title>
            <v-list-item-subtitle>
              オンライン
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list
            nav
            dense
        >
          <v-list-item-group>
            <v-list-item :to="'/register'">
              <v-list-item-title>利用者登録</v-list-item-title>
            </v-list-item>

            <v-list-item :to="'/users'">
              <v-list-item-title>利用者管理</v-list-item-title>
            </v-list-item>

            <v-list-item :to="'conditions'">
              <v-list-item-title>評価項目管理</v-list-item-title>
            </v-list-item>

            <v-list-item :to="'admin'">
              <v-list-item-title>管理者画面</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "app",
  data() {
    return {
      navigation: null,
      drawer: false,
      admin: null
    };
  },
  created() {
   
    firebase.auth().onAuthStateChanged(async user => {
      console.log('user: ', user);
      this.$store.commit("updateUser", user);
      if (user) {
        const token = await user.getIdTokenResult();
        
        this.admin = await token.claims.admin;
      }
    });
    this.checkRoute();


  },
  async mounted() {
    let user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdTokenResult();
      console.log('token: ', token.claims?.admin);
      this.admin = token.claims?.admin;
    }
    console.log(this.admin)
  },
  methods: {
   
    checkRoute() {
      if (this.$route.name === "Login") {
        this.navigation = true;
        return;
      }
      this.navigation = false;
    },
    logout() {
      this.$store.commit('resetState')
      Object.assign(this.$data, this.$options.data.apply(this))
      firebase.auth().signOut().then(() => {
        window.location.reload()
      }).catch((error) => {
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
// 右腕傾き
const FRONT_POINTS = ['頭垂直', '上半身垂直', '下半身垂直', '目水平', '肩水平', '腰水平', '右手', '左手', '右膝屈曲', '左膝屈曲']
const LEFT_POINTS = ['頭垂直', '上半身垂直', '下半身垂直', '左上腕傾き', '左肘', '左膝屈曲']
const LEFT_SIT_POINTS = ['首', '上半身', '左上腕傾き', '左肘', '左股関節', '左膝', '左足']
const RIGHT_POINTS = ['頭垂直', '上半身垂直', '下半身垂直', '右上腕傾き', '右肘', '右膝屈曲']
const RIGHT_SIT_POINTS = ['首', '上半身', '右上腕傾き', '右肘', '右股関節', '右膝', '右足']
export {FRONT_POINTS, LEFT_POINTS, RIGHT_POINTS, LEFT_SIT_POINTS, RIGHT_SIT_POINTS}
</script>


<style scoped>
.container{
  display:flex;
  align-items : center ;
  justify-content:center;
  flex-direction:row ;

}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

.logout-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.logout-button:hover {
  background-color: #c82333;
}
.v-slider--horizontal {
  background-color:#fff !important;
}

</style>
