<template>
  <v-container>
    <div
        v-if="!poseDirectionData.ok"
        style="text-align:center, height: 70vh,align-items:center, justify-content:center, border-radius:15px"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      />
      <p>解析中です。しばらくお待ちください...</p>
    </div>
    <v-row v-show="poseDirectionData.ok">
      <v-col cols="12" md="4" style="text-align: center,display:flex, align-items:center">
        <canvas :ref='`canvas_${poseDirectionData.direction}_${poseDirectionData.pose}`'
                :id="`canvas_${poseDirectionData.direction}_${poseDirectionData.pose}`"/>
      </v-col>
      <!-- <v-divider style="margin-left: 10px;" vertical></v-divider> -->
      <v-col cols="12" md="4" style="text-align: center, display:flex, align-items:center">
        <canvas :ref='`canvas_${poseDirectionData.direction}_${poseDirectionData.pose}_figure`'
                :id="`canvas_${poseDirectionData.direction}_${poseDirectionData.pose}_figure`"/>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="2">
        <h2>{{ poseDirectionData.title }}</h2>
        
        <ul v-if="poseDirectionData.ok && poseDirectionData.name == 'leftSideStanding'">
          <li   v-for="(item, index) in poseDirectionData.analyzedData" :key="index">
              {{ item.point }}:
              {{ (item.point === "頭垂直" ||item.point === "上半身垂直" || item.point === '下半身垂直')?
              ((item.angle[0] == '-')?"前傾":"後傾"):(item?.point === "左腕傾き")?((item.angle[0] == '-')?"後": '前'):(item?.point === "左肘")?((item.angle[0] == '-')?"過伸展": '屈曲'):(item?.point === "左上腕傾き")?((item.angle[0] == '-')?"曲がった": '過伸展'):""}}

            {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}}
          </li>
        </ul>
        <ul v-if="poseDirectionData.ok && poseDirectionData.name == 'rightSideStanding'">
          <li   v-for="(item, index) in poseDirectionData.analyzedData" :key="index">
            {{ item.point }}:
              {{ (item.point === "頭垂直" ||item.point === "上半身垂直" || item.point === '下半身垂直')?
              ((item.angle[0] == '-')?"後傾":"前傾"):(item?.point === "右上腕傾き")?((item.angle[0] == '-')?"後": '前'):(item?.point === "右肘")?((item.angle[0] == '-')?"屈曲": '過伸展'):(item?.point === "右膝屈曲")?((item.angle[0] == '-')?"曲がった": '過伸展'):""}}
            {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}}
          </li>
        </ul>
        <ul v-if="poseDirectionData.ok && poseDirectionData.name == 'leftSideSitting'">
          <li v-for="(item, index) in poseDirectionData.analyzedData" :key="index">
              {{ item.point }}:
              {{ (item.point === '左股関節' || item.point === '左足' || item.point === '左肘')?((item.angle[0] == '-')?"過伸展":"曲がった"): (item.point === '首' || item.point === '上半身')?((item.angle[0] == '-')?"後傾":"前傾"):(item.point === '左膝')?((item.angle[0] == '-')?"曲がった":" 過伸展"):(item.point === '左上腕傾き')?((item.angle[0] == '-')?"後":" 前"):""}}
              {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}}
          </li>
        </ul>
        <ul v-if="poseDirectionData.ok && poseDirectionData.name == 'rightSideSitting'">
          <li   v-for="(item, index) in poseDirectionData.analyzedData" :key="index">
              
            {{ item.point }}:
            {{ (item.point === '右肘' || item.point === '右股関節')?((item.angle[0] == '-')?"曲がった":":過伸展"): (item.point === '首' || item.point === '上半身')?((item.angle[0] == '-')?"前傾":"後傾"):(item.point === '右膝' || item.point === '右足')?((item.angle[0] == '-')?"過伸展":"曲がった"):(item.point === '右上腕傾き')?((item.angle[0] == '-')?"前":" 後"):""}}
              {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}}

              <!-- {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}} -->
              {{ item.angle }}
          </li>
        </ul>
        <ul v-if="poseDirectionData.ok && poseDirectionData.name == 'frontStanding'">
          <li   v-for="(item, index) in poseDirectionData.analyzedData" :key="index">
              {{ item.point }}:
            {{ item?.point === "頭垂直"?((bodyPoints>ancleCenter)?"左傾き":"右傾き"):item.point ==="右手"?(item.angle[0] === '-')?"内側屈曲":"外側屈曲":item.point ==="左手"?(item.angle[0] === '-')?"外側屈曲":"内側屈曲":item.point ==="右膝屈曲"?(item.angle[0] === '-')?"O脚":": X脚":item.point ==="左膝屈曲"?(item.angle[0] === '-')?"X脚":": O脚":(item.point ==="上半身垂直"|| item.point === "下半身垂直"||item.point === "目水平"||item.point === "肩水平"||item.point==="腰水平")?(item.angle[0] === '-')?"左傾き":"右傾き":""}}
              {{(item.angle[0] == '-')?getSlicedText(item.angle): item.angle}}
          </li>
        </ul>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="2">
        <h2></h2>
        <ul v-if="poseDirectionData.ok">
          <li v-for="(condition, index) in poseDirectionData.messages" :key="index">
            <p>{{ condition.name }}</p>
           <p>{{ condition.description.slice(0,7) }}...</p>
            <ConditionImageDisplay :description="condition.description" :url="condition.condImg.toString()"/>
          </li>
            
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConditionImageDisplay from "@/components/ConditionImageDisplay";
import {FRONT_POINTS, LEFT_POINTS, LEFT_SIT_POINTS, RIGHT_POINTS, RIGHT_SIT_POINTS} from "@/App";

export default {
  components: {ConditionImageDisplay},
  name: "PoseCanvas",
  props: ['imageSrc', 'poseDirectionData', 'conditions'],
  data() {
    return {img: null, ok: false, analyzedData: null, regex: null, angles: [], ancleCenter:null, bodyPoints:null}
  },
  async mounted() {
    console.log('data', this.poseDirectionData)
    console.log("Pose Dirrection data  =>", this.poseDirectionData)
    console.log('src', this.condition)
    console.log('bodyPoints: ', this.bodyPoints);
    console.log('ancleCenter: ',this. ancleCenter);
    
    this.initialize()
    this.img = await this.loadImage(this.imageSrc)
    await this.analyze()
  },
  methods: {
    getSlicedText(text) {
      console.log('angleSign: ', text);
      return text.slice(1);
    },
    getAnglePoints() {
      const pd = this.poseDirectionData
      if (pd.name === "frontStanding") return FRONT_POINTS
      if (pd.name === "leftSideStanding") return LEFT_POINTS
      if (pd.name === "rightSideStanding") return RIGHT_POINTS
      if (pd.name === "leftSideSitting") return LEFT_SIT_POINTS
      if (pd.name === "rightSideSitting") return RIGHT_SIT_POINTS
    },
    testCondition(condition, analyzedData) {
      console.log('[testCondition] condition', condition)
      console.log('[testCondition] analyzedData', analyzedData)
      console.log('[testCondition] analyzedPoints', this.points)
      const before = condition
      // 条件式が不正な文字列でないか検証
      if (this.regex.test(condition) && condition.length !== 0) {
        this.points.forEach(pointName => {
          const angleData = analyzedData.find((data) => data.point === pointName)
          if (angleData) {
            condition = condition.replaceAll(pointName, ' ' + angleData.angle)
          }
        })
        try {
          const result = new Function(`return {condition: '${condition}', result: ${condition}}`)()
          console.log("returning result =>", result)
          return result.result;
        } catch (error) {
          alert('条件式が不正です\n' + before)
          return false
        }
      }
      alert('不正な文字列が入力されています\n' + before)
      return false
    },
    initialize() {
      let rWords = ''
      this.points = [...new Set([...LEFT_SIT_POINTS, ...RIGHT_SIT_POINTS, ...RIGHT_POINTS, ...LEFT_POINTS, ...FRONT_POINTS])]
      this.points.sort((a, b) => {
        return b.length - a.length
      })
      this.points.forEach(point => rWords += point + '|')
      const pattern = '^(' + rWords + ' |\\+|-|>|<|&&|=|\\(|\\)|\\|\\||[0-9])+$'
      this.regex = new RegExp(pattern);
    },
    onAnalyzeFinished(data) {
      console.log(JSON.stringify(data))
      // 解析終了フラグを建てる
      // this.poseDirectionData.ok = true

      // 解析結果の角度一覧と、ポイント名を紐づける
      // 解析結果をコンポーネント変数に格納
      this.poseDirectionData.analyzedData = this.getAnglePoints().map((point, index) => {
        return {
          point: point,
          angle: data.angles[index],
        }
      });

      // 解析結果をstoreに格納
      this.$store.commit("setAnalyzeData", {
        poseDirection: this.poseDirectionData.name,
        angles: data.angles,
      });

      this.conditions.forEach(cond => {
        console.log('cond titile=>', cond.direction)
        console.log('cond titilepose=>', this.poseDirectionData.title)
        console.log('this.poseDirectionData.title', this.poseDirectionData.title != cond.direction)
        // このコンポーネントで表示される写真方向に該当する条件のみ検査する
        if (cond.direction != this.poseDirectionData.title) return;
        const match = this.testCondition(cond.condition, this.poseDirectionData.analyzedData)
          console.log('Match fount or not =>', match)
        if (match) {
          this.poseDirectionData.messages.push(cond)
        }
      })
    },
    drawImageScaled(img, ctx) {
      let canvas = ctx.canvas;
      let hRatio = canvas.width / img.width;
      let vRatio = canvas.height / img.height;
      let ratio = Math.min(hRatio, vRatio);
      let centerShift_x = (canvas.width - img.width * ratio) / 2;
      let centerShift_y = (canvas.height - img.height * ratio) / 2;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, img.width, img.height,
          centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    },
    scaleLineKeypoint(ctx, p1, p2, scale) {
      this.line(ctx, scale * p1.position.x, scale * p1.position.y, scale * p2.position.x, scale * p2.position.y)
    },
    lineKeypoint(ctx, p1, p2) { 
      this.line(ctx, p1.position.x, p1.position.y, p2.position.x, p2.position.y)
    },
    srcToFile(src, fileName, mimeType) {
      return (fetch(src)
              .then(function (res) {
                return res.arrayBuffer();
              })
              .then(function (buf) {
                return new File([buf], fileName, {type: mimeType});
              })
      );
    },
    line(ctx, x1, y1, x2, y2) {
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    },
    circle(ctx, x, y, r, fill = false) {
      ctx.beginPath();
      ctx.arc(x, y, r, 0, 2 * Math.PI, false);
      ctx.fill();
      if (fill) ctx.fill()
      else ctx.stroke();
    },
    // Angle increases counterclockwise
    find_angle(A,B,C) {
    var AB = Math.sqrt(Math.pow(B.x-A.x,2)+ Math.pow(B.y-A.y,2));    
    var BC = Math.sqrt(Math.pow(B.x-C.x,2)+ Math.pow(B.y-C.y,2)); 
    var AC = Math.sqrt(Math.pow(C.x-A.x,2)+ Math.pow(C.y-A.y,2));
    return (Math.acos((BC*BC+AB*AB-AC*AC)/(2*BC*AB))* 180) / Math.PI;
    },

    calcAngles(combos) {
      // [keypoints[rightWrist], keypoints[rightElbow], keypoints[rightElbow], keypoints[rightShoulder]]
      let angles = [];
      combos.forEach(combo => {
        // console.log(combo[0].position, combo[1].position)
        // console.log(combo[2].position, combo[3].position)
        // y-axis is positive downward
        let v1x = combo[1].position.x - combo[0].position.x;
        let v1y = -(combo[1].position.y - combo[0].position.y);
        let v2x = combo[3].position.x - combo[2].position.x;
        let v2y = -(combo[3].position.y - combo[2].position.y);
        let angle1 = Math.atan2(v1y, v1x) * 180 / Math.PI
        let angle2 = Math.atan2(v2y, v2x) * 180 / Math.PI
        if (angle1 < 0) angle1 = angle1 + 360
        if (angle2 < 0) angle2 = angle2 + 360
        console.log('angle1', angle1, 'angle2', angle2)
        let angle = angle2 - angle1
        if (angle < -180) angle = angle + 360
        if (angle > 180) angle = angle - 360
        angle = Math.round(angle * 10) / 10
        console.log('finalangle: ', angle);
        angles.push(angle.toString());
      });
      return angles;
    },
    async analyze() {
      const nose = 0;
      const leftEye = 1;
      const rightEye = 2;
      const leftEar = 3;
      const rightEar = 4;
      const leftShoulder = 5;
      const rightShoulder = 6;
      const leftElbow = 7;
      const rightElbow = 8;
      const leftWrist = 9;
      const rightWrist = 10;
      const leftHip = 11;
      const rightHip = 12;
      const leftKnee = 13;
      const rightKnee = 14;
      const leftAnkle = 15;
      const rightAnkle = 16;
      const img = this.img
      const c = new OffscreenCanvas(img.width, img.height)
      const ctx = c.getContext('2d')

      ctx.drawImage(img, 0, 0);
      // const bitmap = c.transferToImageBitmap()
      // console.log(bitmap)

      const calculate = (body) => {
        const keypoints = body.keypoints;
        let ms = {};
        ms.position = {};
        ms.position.x = (keypoints[leftShoulder].position.x + keypoints[rightShoulder].position.x) / 2;
        ms.position.y = (keypoints[leftShoulder].position.y + keypoints[rightShoulder].position.y) / 2;
        let mh = {};
        mh.position = {};
        mh.position.x = (keypoints[leftHip].position.x + keypoints[rightHip].position.x) / 2;
        mh.position.y = (keypoints[leftHip].position.y + keypoints[rightHip].position.y) / 2;
        let ma = {};
        ma.position = {};
        ma.position.x = (keypoints[leftAnkle].position.x + keypoints[rightAnkle].position.x) / 2;
        ma.position.y = (keypoints[leftAnkle].position.y + keypoints[rightAnkle].position.y) / 2;
        this.ancleCenter = (keypoints[leftAnkle].position.x + keypoints[rightAnkle].position.x) / 2;
      
        let x_axis = [{position: {x: 0, y: 0}}, {position: {x: 1, y: 0}}];
        // y-axis is positive downward
        let y_axis = [{position: {x: 0, y: 0}}, {position: {x: 0, y: -1}}];

        let r = img.width / 80;
        ctx.lineWidth = r / 3;

        // get angles
        console.log(this.poseDirectionData.direction, this.poseDirectionData.pose)
        let angles;
        if (this.poseDirectionData.direction === 'front') {
          angles = this.calcAngles([
            [ms, keypoints[nose], mh, ms],
            [mh, ms, ma, ms],
            [ma, mh, ...y_axis],
            [keypoints[rightEye], keypoints[leftEye], ...x_axis],
            [keypoints[rightShoulder], keypoints[leftShoulder], ...x_axis],
            [keypoints[rightHip], keypoints[leftHip], ...x_axis],
            [keypoints[rightWrist], keypoints[rightElbow], keypoints[rightElbow], keypoints[rightShoulder]], //seventh
            [keypoints[leftWrist], keypoints[leftElbow], keypoints[leftElbow], keypoints[leftShoulder]],
            [keypoints[rightKnee], keypoints[rightAnkle], keypoints[rightHip], keypoints[rightKnee]],
            [keypoints[leftKnee], keypoints[leftHip], keypoints[leftAnkle], keypoints[leftKnee]],
          ])
          // angles[6] = Math.abs(leftElbowAngle-180).toFixed(1) 
        } else if (this.poseDirectionData.direction === 'left' && this.poseDirectionData.pose === 'standing') {
          angles = this.calcAngles([
            [keypoints[leftShoulder], keypoints[leftEar], ...y_axis],
            [keypoints[leftHip], keypoints[leftShoulder], ...y_axis],
            [keypoints[leftAnkle], keypoints[leftHip], ...y_axis],
            [keypoints[leftShoulder], keypoints[leftElbow], keypoints[leftShoulder], keypoints[leftHip]],
            [keypoints[leftWrist], keypoints[leftElbow], keypoints[leftElbow], keypoints[leftShoulder]],
            [keypoints[leftKnee], keypoints[leftHip], keypoints[leftAnkle], keypoints[leftKnee]],
          ])
        } else if (this.poseDirectionData.direction === 'left' && this.poseDirectionData.pose === 'sitting') {
  
            angles = this.calcAngles([
            [keypoints[leftHip], keypoints[leftShoulder], keypoints[leftShoulder], keypoints[leftEar]],
            [...y_axis, keypoints[leftHip], keypoints[leftShoulder]],
            [keypoints[leftShoulder], keypoints[leftElbow], keypoints[leftShoulder], keypoints[leftHip]],
            [keypoints[leftWrist], keypoints[leftElbow], keypoints[leftElbow], keypoints[leftShoulder]],
            [keypoints[leftHip], keypoints[leftShoulder], keypoints[leftHip], keypoints[leftKnee]],
            [keypoints[leftKnee], keypoints[leftHip], keypoints[leftKnee], keypoints[leftAnkle]],
            [...x_axis, keypoints[leftAnkle], keypoints[leftKnee]]
          ])
          // angles[3] = Math.abs(leftElbowAngle)?.toFixed(2).toString()
          // angles[2] = (Math.abs(Number(左上腕傾き)+ Math.abs(Number(angles[0]))))?.toFixed(2).toString()
        } else if (this.poseDirectionData.direction === 'right' && this.poseDirectionData.pose === 'standing') {
          angles = this.calcAngles([
            [keypoints[rightShoulder], keypoints[rightEar], ...y_axis],
            [keypoints[rightHip], keypoints[rightShoulder], ...y_axis],
            [keypoints[rightAnkle], keypoints[rightHip], ...y_axis],
            [keypoints[rightShoulder], keypoints[rightElbow], keypoints[rightShoulder], keypoints[rightHip]],
            [keypoints[rightWrist], keypoints[rightElbow], keypoints[rightElbow], keypoints[rightShoulder]],
            [keypoints[rightKnee], keypoints[rightHip], keypoints[rightAnkle], keypoints[rightKnee]],
          ])
        } else if (this.poseDirectionData.direction === 'right' && this.poseDirectionData.pose === 'sitting') {
          angles = this.calcAngles([
            [keypoints[rightHip], keypoints[rightShoulder], keypoints[rightShoulder], keypoints[rightEar]],
            [...y_axis, keypoints[rightHip], keypoints[rightShoulder]],
            [keypoints[rightShoulder], keypoints[rightElbow], keypoints[rightShoulder], keypoints[rightHip]],
            [keypoints[rightWrist], keypoints[rightElbow], keypoints[rightElbow], keypoints[rightShoulder]],
            [keypoints[rightHip], keypoints[rightShoulder], keypoints[rightHip], keypoints[rightKnee]],
            [keypoints[rightKnee], keypoints[rightHip], keypoints[rightKnee], keypoints[rightAnkle]],
            [...x_axis, keypoints[rightAnkle], keypoints[rightKnee]]
          ])
        }
        if (this.poseDirectionData.direction === 'front') {
          ctx.strokeStyle = 'DodgerBlue';
          this.lineKeypoint(ctx, keypoints[leftShoulder], keypoints[rightShoulder]);
          this.lineKeypoint(ctx, keypoints[leftShoulder], keypoints[leftElbow]);
          this.lineKeypoint(ctx, keypoints[leftShoulder], keypoints[leftHip]);
          this.lineKeypoint(ctx, keypoints[rightShoulder], keypoints[rightElbow]);
          this.lineKeypoint(ctx, keypoints[rightShoulder], keypoints[rightHip]);
          this.lineKeypoint(ctx, keypoints[leftElbow], keypoints[leftWrist]);
          this.lineKeypoint(ctx, keypoints[rightElbow], keypoints[rightWrist]);
          this.lineKeypoint(ctx, keypoints[leftHip], keypoints[rightHip]);
          this.lineKeypoint(ctx, keypoints[leftHip], keypoints[leftKnee]);
          this.lineKeypoint(ctx, keypoints[rightHip], keypoints[rightKnee]);
          this.lineKeypoint(ctx, keypoints[leftKnee], keypoints[leftAnkle]);
          this.lineKeypoint(ctx, keypoints[rightKnee], keypoints[rightAnkle]);

          ctx.strokeStyle = 'DodgerBlue';
          ctx.fillStyle = 'white';
          for (const keypoint of keypoints) {
            let exceptions = ['leftEye', 'rightEye']
            if (exceptions.includes(keypoint.part)) continue;
            // console.log(`${keypoint.part}: (${keypoint.position.x},${keypoint.position.y})`);
            this.circle(ctx, keypoint.position.x, keypoint.position.y, r)
          }
          this.circle(ctx, ms.position.x, ms.position.y, r)
          this.circle(ctx, mh.position.x, mh.position.y, r)

          ctx.strokeStyle = 'red';
          this.line(ctx, ma.position.x, ma.position.y, ma.position.x, 0)
        } else if (this.poseDirectionData.direction === 'left') {
          ctx.strokeStyle = 'red'
          ctx.beginPath();
          let angle = Math.atan2(
              keypoints[leftEar].position.y - keypoints[leftShoulder].position.y,
              keypoints[leftEar].position.x - keypoints[leftShoulder].position.x,
          );
          let degree = (180 + angle * 180 / Math.PI) | 0;
          ctx.arc(keypoints[leftShoulder].position.x, keypoints[leftShoulder].position.y, r * 3, Math.PI, angle);
          ctx.stroke();

          ctx.strokeStyle = 'DodgerBlue';
          this.lineKeypoint(ctx, keypoints[leftShoulder], keypoints[leftElbow])
          this.lineKeypoint(ctx, keypoints[leftElbow], keypoints[leftWrist])
          this.lineKeypoint(ctx, keypoints[leftHip], keypoints[leftKnee])
          this.lineKeypoint(ctx, keypoints[leftKnee], keypoints[leftAnkle])
          this.lineKeypoint(ctx, keypoints[leftEar], keypoints[leftShoulder])
          this.lineKeypoint(ctx, keypoints[leftShoulder], keypoints[leftHip])

          ctx.strokeStyle = 'DodgerBlue';
          ctx.fillStyle = 'white';
          for (const keypoint of keypoints) {
            let points = [
              'nose', 'leftEar', 'leftShoulder', 'leftElbow', 'leftWrist', 'leftHip', 'leftKnee', 'leftAnkle'
            ]
            if (points.includes(keypoint.part)) {
              // console.log(`${keypoint.part}: (${keypoint.position.x},${keypoint.position.y})`);
              this.circle(ctx, keypoint.position.x, keypoint.position.y, r)
            }
          }

          ctx.strokeStyle = 'red'
          if (this.poseDirectionData.pose === 'standing') {
            this.line(
                ctx, keypoints[leftAnkle].position.x, keypoints[leftAnkle].position.y,
                keypoints[leftAnkle].position.x, 0
            )
          } else if (this.poseDirectionData.pose === 'sitting') {
            this.line(
                ctx, keypoints[leftHip].position.x, keypoints[leftHip].position.y,
                keypoints[leftHip].position.x, 0
            )
          }

         ctx.beginPath();
         ctx.fillStyle = 'orange';
          ctx.strokeStyle = 'orange';
          ctx.font = `60px serif`;
          ctx.lineWidth = 1;
          ctx.fillText(`${degree}°`, keypoints[leftShoulder].position.x - r * 3 - 32,
              keypoints[leftShoulder].position.y + 16)
          ctx.strokeText(`${degree}°`, keypoints[leftShoulder].position.x - r * 3 - 32,
              keypoints[leftShoulder].position.y + 16)
          ctx.fill();
          ctx.stroke();
        } else if (this.poseDirectionData.direction === 'right') {
          ctx.strokeStyle = 'red';
          ctx.beginPath();
          let angle = Math.atan2(
              keypoints[rightEar].position.y - keypoints[rightShoulder].position.y,
              keypoints[rightEar].position.x - keypoints[rightShoulder].position.x,
          );
          let degree = 180-(180 + angle * 180 / Math.PI) | 0;
          ctx.arc(keypoints[rightShoulder].position.x, keypoints[rightShoulder].position.y, r * 4, Math.PI*1/16, angle, true);
          ctx.stroke();

          ctx.strokeStyle = 'DodgerBlue';
          this.lineKeypoint(ctx, keypoints[rightShoulder], keypoints[rightElbow]);
          this.lineKeypoint(ctx, keypoints[rightElbow], keypoints[rightWrist]);
          this.lineKeypoint(ctx, keypoints[rightHip], keypoints[rightKnee]);
          this.lineKeypoint(ctx, keypoints[rightKnee], keypoints[rightAnkle]);
          this.lineKeypoint(ctx, keypoints[rightEar], keypoints[rightShoulder]);
          this.lineKeypoint(ctx, keypoints[rightShoulder], keypoints[rightHip])

          ctx.strokeStyle = 'DodgerBlue';
          ctx.fillStyle = 'white';
          for (const keypoint of keypoints) {
            let points = [
              'nose', 'rightEar', 'rightShoulder', 'rightElbow', 'rightWrist', 'rightHip', 'rightKnee', 'rightAnkle'
            ]
            if (points.includes(keypoint.part)) {
              // console.log(`${keypoint.part}: (${keypoint.position.x},${keypoint.position.y})`);
              this.circle(ctx, keypoint.position.x, keypoint.position.y, r)
            }
          }

          ctx.strokeStyle = 'red';
          if (this.poseDirectionData.pose === 'standing') {
            this.line(
                ctx, keypoints[rightAnkle].position.x, keypoints[rightAnkle].position.y,
                keypoints[rightAnkle].position.x, 0
            );
          } else if (this.poseDirectionData.pose === 'sitting') {
            this.line(
                ctx, keypoints[rightHip].position.x, keypoints[rightHip].position.y,
                keypoints[rightHip].position.x, 0
            );

          }

          ctx.beginPath();
          ctx.fillStyle = 'orange';
          ctx.strokeStyle = 'orange';
          ctx.font = "60px serif";
          ctx.lineWidth = 1;
          ctx.fillText(`${degree}°`, keypoints[leftShoulder].position.x - r * 3 + 32,
              keypoints[leftShoulder].position.y + 16);
          ctx.strokeText(`${degree}°`, keypoints[leftShoulder].position.x - r * 3 + 32,
              keypoints[leftShoulder].position.y + 16);
          ctx.fill();
          ctx.stroke();
        }
        // this.$emit('finished', {'angles': angles, 'dataURL': c.toDataURL()})
        console.log('angles', angles)
        this.onAnalyzeFinished({'angles': angles})
        // this.$emit('finished', {'angles': angles, 'messages': this.poseDirectionData.messages})
        this.angles = angles
        const mC = document.getElementById(`canvas_${this.poseDirectionData.direction}_${this.poseDirectionData.pose}`)
        mC.width = 400
        mC.height = 400 * img.height / img.width
        const mCtx = mC.getContext('2d')

        let canvas = mCtx.canvas;
        let hRatio = canvas.width / img.width;
        let vRatio = canvas.height / img.height;
        let ratio = Math.min(hRatio, vRatio);
        let centerShift_x = (canvas.width - img.width * ratio) / 2;
        let centerShift_y = (canvas.height - img.height * ratio) / 2;
        const stickFigureBitmap = c.transferToImageBitmap()
        mCtx.clearRect(0, 0, canvas.width, canvas.height);
        mCtx.drawImage(img, 0, 0, img.width, img.height,
            centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
        mCtx.drawImage(stickFigureBitmap, 0, 0, img.width, img.height,
            centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
        // console.log("canvas ", mCtx, mCtx.width)

        // mCtx.drawImage(img, 0, 0)
        // mCtx.drawImage(c.transferToImageBitmap(), 0, 0)

        function rotate(cx, cy, x, y, angle) {
          let cos = Math.cos(angle),
              sin = Math.sin(angle),
              nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
              ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
          return [nx, ny];
        }

        function polygon(ctx, x, y, radius, sides, angle = 0) {
          if (sides < 3) return;
          let a = ((Math.PI * 2) / sides);
          ctx.beginPath();
          ctx.translate(x, y);
          ctx.rotate(angle)
          ctx.moveTo(radius, 0);
          for (let i = 1; i < sides; i++) {
            ctx.lineTo(radius * Math.cos(a * i), radius * Math.sin(a * i));
          }
          ctx.rotate(-angle)
          ctx.translate(-x, -y);
          ctx.fillStyle = 'white';
          ctx.fill();
          ctx.closePath();
        }

        const figureCanvas = document.getElementById(`canvas_${this.poseDirectionData.direction}_${this.poseDirectionData.pose}_figure`)
        const figureCtx = figureCanvas.getContext('2d')
        figureCanvas.width = 400
        figureCanvas.height = 400 * img.height / img.width
        // console.log("canvas figure", figureCtx, figureCtx.width)
        hRatio = figureCanvas.width / img.width;
        vRatio = figureCanvas.height / img.height;
        ratio = Math.min(hRatio, vRatio);
        const scale = 400 / img.width
        figureCtx.clearRect(0, 0, figureCanvas.width, figureCanvas.height);


        const mex = scale * ((keypoints[leftEye].position.x + keypoints[rightEye].position.x) / 2)
        const mey = scale * ((keypoints[leftEye].position.y + keypoints[rightEye].position.y) / 2)
        const msx = scale * ms.position.x
        const msy = scale * ms.position.y
        const ry = Math.sqrt((msy - mey) ** 2 + (msx - mex) ** 2) * 0.8
        const rx = ry / 1.12
        const angle = Math.atan2(keypoints[leftEar].position.y - keypoints[rightEar].position.y, keypoints[leftEar].position.x - keypoints[rightEar].position.x)

        if (this.poseDirectionData.direction === 'front') {
          // Draw chest
          figureCtx.strokeStyle = 'orange'
          const cApeX = scale * (ms.position.x * 2 + mh.position.x * 1) / 3
          const cApeY = scale * (ms.position.y * 2 + mh.position.y * 1) / 3
          this.line(figureCtx, cApeX, cApeY, scale * keypoints[leftShoulder].position.x, scale * keypoints[leftShoulder].position.y)
          this.line(figureCtx, cApeX, cApeY, scale * keypoints[rightShoulder].position.x, scale * keypoints[rightShoulder].position.y)
          this.line(figureCtx, scale * keypoints[leftShoulder].position.x, scale * keypoints[leftShoulder].position.y, scale * keypoints[rightShoulder].position.x, scale * keypoints[rightShoulder].position.y)

          // Draw hip
          let htl = {}, htr = {}, hbl = {}, hbr = {}
          htl.position = {}
          htr.position = {}
          hbl.position = {}
          hbr.position = {}
          htl.position.x = scale * (keypoints[leftShoulder].position.x + keypoints[leftHip].position.x * 4) / 5
          htl.position.y = scale * (keypoints[leftShoulder].position.y + keypoints[leftHip].position.y * 4) / 5
          htr.position.x = scale * (keypoints[rightShoulder].position.x + keypoints[rightHip].position.x * 4) / 5
          htr.position.y = scale * (keypoints[rightShoulder].position.y + keypoints[rightHip].position.y * 4) / 5
          hbl.position.x = scale * keypoints[leftHip].position.x * 2 - htl.position.x
          hbl.position.y = scale * keypoints[leftHip].position.y * 2 - htl.position.y
          hbr.position.x = scale * keypoints[rightHip].position.x * 2 - htr.position.x
          hbr.position.y = scale * keypoints[rightHip].position.y * 2 - htr.position.y
          this.lineKeypoint(figureCtx, htl, htr)
          this.lineKeypoint(figureCtx, hbl, hbr)
          this.lineKeypoint(figureCtx, htl, hbl)
          this.lineKeypoint(figureCtx, htr, hbr)

          // Draw face
          figureCtx.fillStyle = 'blue';
          console.log('angle: ', angle)
          figureCtx.beginPath();
          figureCtx.ellipse(mex, mey, rx, ry, angle, 0, Math.PI * 4);
          figureCtx.fill();
          figureCtx.fillStyle = 'white';
          figureCtx.beginPath();
          figureCtx.ellipse(mex, mey, rx - 2, ry - 2, angle, 0, Math.PI * 2);
          figureCtx.fill();
          const np1 = rotate(mex, mey, mex + rx / 4, mey - ry / 4, Math.PI / 2 - angle)
          const np2 = rotate(mex, mey, mex + rx / 4, mey + ry / 4, Math.PI / 2 - angle)
          figureCtx.fillStyle = 'blue';
          figureCtx.strokeStyle = 'blue';
          figureCtx.lineWidth = 2
          this.circle(figureCtx, np1[0], np1[1], rx / 8, true)
          this.circle(figureCtx, np2[0], np2[1], rx / 8, true)
          figureCtx.beginPath()
          figureCtx.arc(mex, mey, ry * 3 / 5, angle + Math.PI / 2 - Math.PI / 5, angle + Math.PI / 2 + Math.PI / 5);
          figureCtx.stroke()

          // Draw lines
          figureCtx.strokeStyle = 'DodgerBlue'
          this.scaleLineKeypoint(figureCtx, keypoints[leftShoulder], keypoints[leftElbow], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[rightShoulder], keypoints[rightElbow], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[leftElbow], keypoints[leftWrist], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[rightElbow], keypoints[rightWrist], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[leftHip], keypoints[leftKnee], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[rightHip], keypoints[rightKnee], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[leftKnee], keypoints[leftAnkle], scale);
          this.scaleLineKeypoint(figureCtx, keypoints[rightKnee], keypoints[rightAnkle], scale);
          const htmX = (htl.position.x + htr.position.x) / 2
          const htmY = (htl.position.y + htr.position.y) / 2
          this.line(figureCtx, htmX, htmY, cApeX, cApeY)
          const mEx = mex + Math.sin(-angle) * ry
          const mEy = mey + Math.cos(-angle) + ry
          this.line(figureCtx, mEx, mEy, msx, msy)

          // Draw joint
          figureCtx.lineWidth = 1
          figureCtx.strokeStyle = 'blue'
          figureCtx.fillStyle = 'white'
          this.circle(figureCtx, scale * keypoints[leftElbow].position.x, scale * keypoints[leftElbow].position.y, 5)
          this.circle(figureCtx, scale * keypoints[rightElbow].position.x, scale * keypoints[rightElbow].position.y, 5)

          figureCtx.lineWidth = 2
          polygon(figureCtx, scale * keypoints[leftWrist].position.x, scale * keypoints[leftWrist].position.y, 8, 5)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[rightWrist].position.x, scale * keypoints[rightWrist].position.y, 8, 5)
          figureCtx.stroke()
          figureCtx.lineWidth = 1
          polygon(figureCtx, scale * keypoints[leftKnee].position.x, scale * keypoints[leftKnee].position.y, 6, 4)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[rightKnee].position.x, scale * keypoints[rightKnee].position.y, 6, 4)
          figureCtx.stroke()
          figureCtx.lineWidth = 2
          polygon(figureCtx, scale * keypoints[leftAnkle].position.x, scale * keypoints[leftAnkle].position.y, 8, 4, Math.PI / 4)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[rightAnkle].position.x, scale * keypoints[rightAnkle].position.y, 8, 4, Math.PI / 4)
          figureCtx.stroke()
         
          figureCtx.strokeStyle = 'red'
          figureCtx.lineWidth = 1;
          this.line(figureCtx, scale * ma.position.x, scale * ma.position.y, scale * ma.position.x, 0)
        }
        if (this.poseDirectionData.direction === 'left') {
          // drawing polygins
            figureCtx.strokeStyle = 'red'
          figureCtx.lineWidth = 1;
            if (this.poseDirectionData.pose === 'standing') {
    
             // Draw chest and hip
          figureCtx.strokeStyle = 'orange'
          figureCtx.lineWidth = 2
          figureCtx.fill();
          figureCtx.fillStyle = 'red';
          polygon(figureCtx, scale * keypoints[leftShoulder].position.x, scale * keypoints[leftShoulder].position.y, scale*50, 3, Math.PI)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[leftHip].position.x, scale * keypoints[leftHip].position.y, scale*50, 4, Math.PI / 4)
          figureCtx.stroke()
          
         
          }
          if (this.poseDirectionData.pose === 'sitting') {
            this.line(
                figureCtx, scale * keypoints[leftHip].position.x, scale * keypoints[leftHip].position.y,
                scale * keypoints[leftHip].position.x, 0
                )
                this.line(
                 figureCtx, scale *keypoints[leftAnkle].position.x, scale *keypoints[leftAnkle].position.y,
                 scale *keypoints[leftAnkle].position.x +100, scale *keypoints[leftAnkle].position.y
               );

                this.line(
                 figureCtx, scale *keypoints[leftAnkle].position.x, scale *keypoints[leftAnkle].position.y,
                 scale *keypoints[leftAnkle].position.x - 100, scale *keypoints[leftAnkle].position.y
               );
             // Draw chest and hip
          figureCtx.strokeStyle = 'orange'
          figureCtx.lineWidth = 2
          polygon(figureCtx, scale * keypoints[leftShoulder].position.x, scale * keypoints[leftShoulder].position.y, scale*30, 3, Math.PI)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[leftHip].position.x, scale * keypoints[leftHip].position.y, scale*30, 4, Math.PI / 4)
          figureCtx.stroke()
          }
          // Draw lines
          figureCtx.strokeStyle = 'DodgerBlue'
          this.scaleLineKeypoint(figureCtx, keypoints[leftShoulder], keypoints[leftElbow], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[leftElbow], keypoints[leftWrist], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[leftHip], keypoints[leftKnee], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[leftKnee], keypoints[leftAnkle], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[leftEar], keypoints[leftShoulder], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[leftShoulder], keypoints[leftHip], scale)

         

          // Draw face
          const angle = Math.atan2(keypoints[nose].position.y - keypoints[leftEar].position.y, keypoints[nose].position.x - keypoints[leftEar].position.x)
          figureCtx.strokeStyle = 'blue'
          figureCtx.lineWidth = 1
          polygon(figureCtx, scale * keypoints[nose].position.x, scale * keypoints[nose].position.y, 6, 3, angle)
          figureCtx.stroke()
          figureCtx.fillStyle = 'blue';
          const fcx = scale * (keypoints[leftEar].position.x * 4 + keypoints[nose].position.x) / 5
          const fcy = scale * (keypoints[leftEar].position.y * 4 + keypoints[nose].position.y) / 5
          const rl = Math.sqrt((fcx - scale * keypoints[nose].position.x) ** 2 + (fcy - scale * keypoints[nose].position.y) ** 2)
          figureCtx.beginPath()
          figureCtx.ellipse(fcx, fcy, rl, rl * 1.2, angle, 0, Math.PI * 2)
          figureCtx.fill()
          figureCtx.fillStyle = 'white'
          figureCtx.beginPath()
          figureCtx.ellipse(fcx, fcy, rl - 2, rl * 1.2 - 2, angle, 0, Math.PI * 2)
          figureCtx.fill()

          figureCtx.strokeStyle = 'DodgerBlue'
          // Draw points
          this.circle(figureCtx, scale * keypoints[leftElbow].position.x, scale * keypoints[leftElbow].position.y, 5)
          this.circle(figureCtx, scale * keypoints[leftHip].position.x, scale * keypoints[leftHip].position.y, 5)
          this.circle(figureCtx, scale * keypoints[leftShoulder].position.x, scale * keypoints[leftShoulder].position.y, 5)
          figureCtx.lineWidth = 1
          polygon(figureCtx, scale * keypoints[leftKnee].position.x, scale * keypoints[leftKnee].position.y, 6, 4)
          figureCtx.stroke()
        
          polygon(figureCtx, scale * keypoints[leftAnkle].position.x, scale * keypoints[leftAnkle].position.y, 8, 4, Math.PI / 4)
          figureCtx.stroke()
      

        
        
          
          figureCtx.lineWidth = 2
          figureCtx.strokeStyle = 'blue'
            
          polygon(figureCtx, scale * keypoints[leftWrist].position.x, scale * keypoints[leftWrist].position.y, 8, 5)
          figureCtx.stroke()
        }
        // if (this.poseDirectionData.direction === 'left' && this.poseDirectionData.pose === 'sitting') {
        //   this.line(
        //       figureCtx, scale * keypoints[leftHip].position.x, scale * keypoints[leftHip].position.y,
        //       scale * keypoints[leftHip].position.x, 0
        //   )
        // }
        if (this.poseDirectionData.direction === 'right') {
          // Draw lines
           figureCtx.strokeStyle = 'red'
          figureCtx.lineWidth = 1;
          if (this.poseDirectionData.pose === 'standing') {
          
               // Draw chest and hip
          figureCtx.strokeStyle = 'orange'
          figureCtx.lineWidth = 2
          
          polygon(figureCtx, scale * keypoints[rightShoulder].position.x, scale * keypoints[rightShoulder].position.y, scale*50 , 3)
          figureCtx.stroke()
        
          polygon(figureCtx, scale * keypoints[rightHip].position.x, scale * keypoints[rightHip].position.y, scale*50, 4, Math.PI / 4)
          figureCtx.stroke()
          }
          if (this.poseDirectionData.pose === 'sitting') {
            this.line(
                figureCtx, scale * keypoints[rightHip].position.x, scale * keypoints[rightHip].position.y,
                scale * keypoints[rightHip].position.x, 0
            )
            this.line(
                 figureCtx, scale *keypoints[rightAnkle].position.x, scale *keypoints[rightAnkle].position.y,
                 scale *keypoints[rightAnkle].position.x +100, scale *keypoints[rightAnkle].position.y
               );

                this.line(
                 figureCtx, scale *keypoints[rightAnkle].position.x, scale *keypoints[rightAnkle].position.y,
                 scale *keypoints[rightAnkle].position.x - 100, scale *keypoints[rightAnkle].position.y
               );
        
               // Draw chest and hip
         
          figureCtx.strokeStyle = 'orange'
          figureCtx.lineWidth = 2
          polygon(figureCtx, scale * keypoints[rightShoulder].position.x, scale * keypoints[rightShoulder].position.y, scale*30 , 3)
          figureCtx.stroke()
        
          polygon(figureCtx, scale * keypoints[rightHip].position.x, scale * keypoints[rightHip].position.y, scale*30, 4, Math.PI / 4)
          figureCtx.stroke()
          }
          figureCtx.strokeStyle = 'DodgerBlue'
          figureCtx.lineWidth = 1
          this.scaleLineKeypoint(figureCtx, keypoints[rightShoulder], keypoints[rightElbow], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[rightElbow], keypoints[rightWrist], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[rightHip], keypoints[rightKnee], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[rightKnee], keypoints[rightAnkle], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[rightEar], keypoints[rightShoulder], scale)
          this.scaleLineKeypoint(figureCtx, keypoints[rightShoulder], keypoints[rightHip], scale)

       

          // Draw face
          const angle = Math.atan2(keypoints[nose].position.y - keypoints[rightEar].position.y, keypoints[nose].position.x - keypoints[rightEar].position.x)
          figureCtx.strokeStyle = 'blue'
          figureCtx.lineWidth = 1
          polygon(figureCtx, scale * keypoints[nose].position.x, scale * keypoints[nose].position.y, 6, 3, angle)
          figureCtx.stroke()
          figureCtx.fillStyle = 'blue';
          const fcx = scale * (keypoints[rightEar].position.x * 4 + keypoints[nose].position.x) / 5
          const fcy = scale * (keypoints[rightEar].position.y * 4 + keypoints[nose].position.y) / 5
          const rl = Math.sqrt((fcx - scale * keypoints[nose].position.x) ** 2 + (fcy - scale * keypoints[nose].position.y) ** 2)
          figureCtx.beginPath()
          figureCtx.ellipse(fcx, fcy, rl, rl * 1.2, angle, 0, Math.PI * 2)
          figureCtx.fill()
          figureCtx.fillStyle = 'white'
          figureCtx.beginPath()
          figureCtx.ellipse(fcx, fcy, rl - 2, rl * 1.2 - 2, angle, 0, Math.PI * 2)
          figureCtx.fill()

          figureCtx.strokeStyle = 'DodgerBlue'
          // Draw points
          this.circle(figureCtx, scale * keypoints[rightElbow].position.x, scale * keypoints[rightElbow].position.y, 5)
           this.circle(figureCtx, scale * keypoints[rightHip].position.x, scale * keypoints[rightHip].position.y, 5)
          this.circle(figureCtx, scale * keypoints[rightShoulder].position.x, scale * keypoints[rightShoulder].position.y, 5)
          figureCtx.lineWidth = 1
          polygon(figureCtx, scale * keypoints[rightKnee].position.x, scale * keypoints[rightKnee].position.y, 6, 4)
          figureCtx.stroke()
         

         
           figureCtx.lineWidth = 2
           
          figureCtx.strokeStyle = 'blue'
          polygon(figureCtx, scale * keypoints[rightWrist].position.x, scale * keypoints[rightWrist].position.y, 8, 5)
          figureCtx.stroke()
          polygon(figureCtx, scale * keypoints[rightAnkle].position.x, scale * keypoints[rightAnkle].position.y, 8, 4, Math.PI / 4)
          figureCtx.stroke()
        }
        // if (this.poseDirectionData.direction === 'right' && this.poseDirectionData.pose === 'sitting') {
        //   this.line(
        //       figureCtx, scale * keypoints[rightHip].position.x, scale * keypoints[rightHip].position.y,
        //       scale * keypoints[rightHip].position.x, 0
        //   )
        // }
      }
      const worker = new Worker('./worker.js', {type: 'module'});

      const send = message => worker.postMessage({
        message
      })

      worker.onmessage = async e => {
        calculate(e.data)
        console.log('e.data: ', e.data);
        this.ancleCenter = await (e.data.keypoints[leftAnkle].position.x + e.data.keypoints[rightAnkle].position.x) / 2;
        this.bodyPoints = await e.data.keypoints[nose].position.x
        worker.terminate()
        const imageBlob = await new Promise(resolve => this.$refs[`canvas_${this.poseDirectionData.direction}_${this.poseDirectionData.pose}`].toBlob(resolve));
        const figureBlob = await new Promise(resolve => this.$refs[`canvas_${this.poseDirectionData.direction}_${this.poseDirectionData.pose}_figure`].toBlob(resolve));
        console.log('this.poseDirectionData.messages')
        this.$emit('rendered', {
          'messages': this.poseDirectionData.messages,
          'angles': this.angles,
          'data': this.poseDirectionData,
          'imageBlob': new File([imageBlob], 'imageBlob.png', {type: imageBlob.type}),
          'figureBlob': new File([figureBlob], 'figureBlob.png', {type: figureBlob.type})
        })
      }

      // send({bitmap: bitmap, width: c.width, height: c.height})
      const file = await this.srcToFile(
          this.imageSrc,
          'human.jpg',
          'image/jpeg'
      )
      send({image: file, pose: this.poseDirectionData.pose, direction: this.poseDirectionData.direction})
    }

  }
}
</script>

<style scoped>

</style>
