<template>
  <v-container>
    <v-card class="mx-auto" max-width="700">
      <!-- <button class="button" v-on:click="adult = !adult">
        {{ !adult ? "子供" : "大人" }}
      </button> -->
      <v-radio-group v-model="adult" row style="padding-left: 10px; padding-top: 10px;">
        <v-radio label="子供" :value="false"></v-radio>
        <v-radio label="大人" :value="true"></v-radio>
      </v-radio-group>
      <v-card-title>必要情報・画像ファイルの入力</v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col md="4" cols="12">
           番号4~7桁(半角英数字)
              <RequiredBadge></RequiredBadge>
            </v-col>
            <v-col md="8">
              <v-text-field
                v-model="form.number"
                :rules="[rules.required, rules.digit7, rules.alphabetOrNum]"
                counter="7"
                placeholder="1234"
                outlined
                autocomplete="off"
                dense
              ></v-text-field>
              <p>{{adult === false ?"番号には、学年・組・出席番号を入力してください。":"番号には、個人として特定できる英数字を入力してください。"}}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-col md="4" cols="12">
              {{
                adult === false ? "お子様の身長(cm)" : "あなたの身長(cm)"
              }}
              <RequiredBadge></RequiredBadge>
            </v-col>
            <v-col md="8">
              <v-text-field
                v-model="form.height"
                :rules="[rules.required, rules.number]"
                placeholder="100.0"
                suffix="cm"
                outlined
                autocomplete="off"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4" cols="12">
             
                Eメールアドレス
            
              <RequiredBadge></RequiredBadge>
            </v-col>
            <v-col md="8">
              <v-text-field
                v-model="form.email"
                :rules="[rules.required, rules.email]"
                placeholder="email@sample.com"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4" class="first__col" cols="12">
              正面写真
             
            </v-col>
            <v-col md="7" cols="12">
              <v-file-input
                v-model="form.frontStandingImg"
                truncate-length="15"
                placeholder="こちらをクリックしてファイルを選択"
                outlined
                dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
             <v-col>
                <RotateDialog
                  @imageUpdate="updateImage($event, 'frontStandingImg')"
                  :image-src="images(form.frontStandingImg)"
                />
              </v-col>
          </v-row>

          <v-row>
            <v-col md="4" class="first__col" cols="12">
              立ち右側面写真
            
            </v-col>
            <v-col md="7" cols="12">
              <v-file-input
                v-model="form.rightSideStandingImg"
                truncate-length="15"
                placeholder="こちらをクリックしてファイルを選択"
                outlined
                dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
              <v-col>
                <RotateDialog
                  @imageUpdate="updateImage($event, 'rightSideStandingImg')"
                  :image-src="images(form.rightSideStandingImg)"
                />
              </v-col>
          </v-row>

          <v-row>
            <v-col md="4" class="first__col" cols="12">
             立ち左側面写真
            
            </v-col>
            <v-col md="7" cols="12">
              <v-file-input
                v-model="form.leftSideStandingImg"
                truncate-length="15"
                placeholder="こちらをクリックしてファイルを選択"
                outlined
                dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <p>※写真では、耳が見えるように髪の毛をまとめてください</p>
            </v-col>
              <v-col>
                <RotateDialog
                  @imageUpdate="updateImage($event, 'leftSideStandingImg')"
                  :image-src="images(form.leftSideStandingImg)"
                />
              </v-col>
          </v-row>

          <v-row>
            <v-col md="4" class="first__col" cols="12">
          座り右側面写真
              
            </v-col>
            <v-col md="7" cols="12">
              <v-file-input
                v-model="form.rightSideSittingImg"
                truncate-length="15"
                placeholder="こちらをクリックしてファイルを選択"
                outlined
                dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <p>※写真では、耳が見えるように髪の毛をまとめてください</p>
            </v-col>
            <v-col>
                <RotateDialog
                  @imageUpdate="updateImage($event, 'rightSideSittingImg')"
                  :image-src="images(form.rightSideSittingImg)"
                />
              </v-col>
          </v-row>

          <v-row>
            <v-col md="4" class="first__col" cols="12">
            座り左側面写真
           
            </v-col>
            <v-col md="7" cols="12">
              <v-file-input
                v-model="form.leftSideSittingImg"
                truncate-length="15"
                placeholder="こちらをクリックしてファイルを選択"
                outlined
                dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <p>※写真では、耳が見えるように髪の毛をまとめてください</p>
            </v-col>
               <v-col>
                <RotateDialog
                  @imageUpdate="updateImage($event, 'leftSideSittingImg')"
                  :image-src="images(form.leftSideSittingImg)"
                />
              </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import RequiredBadge from "../components/RequiredBadge.vue";
import RotateDialog from "../components/RotateDialog.vue";

export default {
  components: {
    RequiredBadge,
    RotateDialog,
  },
  props: {
    form: {
      number: String,
      email: String,
      height: String,
      frontStandingImg: File,
      leftSideStandingImg: File,
      leftSideSittingImg: File,
      rightSideStandingImg: File,
      rightSideSittingImg: File,
    },
  },
  data() {
    return {
      dialog: false,
      adult: false,
      rules: {
        required: (value) => !!value || "必須項目です",
        digit7: (value) => {
          if (value == null) {
            return false;
          }
          return value.length >=4&& value.length <=7 || "4~7桁の番号(半角英数字)を入力してください";
        },
        alphabetOrNum: (value) => {
          const pattern = /^([0-9]|[A-Z]|[a-z])+$/;
          return pattern.test(value) || "半角英数字を入力してください";
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || "有効なEメールアドレスを入力してください"
          );
        },
        number: (value) => {
          const pattern = /^[0-9]+(\.[0-9]+)?$/;
          return pattern.test(value) || "半角数字で入力してください";
        },
      },
    };
  },
  methods: {
    images(file) {
      if (!file) return;
      return URL.createObjectURL(file);
    },
    updateImage(blob, directionImg) {
      this.form[directionImg] = new File([blob], this.form[directionImg].name);
    },
  },
 
};
</script>

<style scoped lang="scss">
.first__col {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.px-1 {
  padding-left: 5em;
  padding-right: 100px;
}

.text-center {
  justify-content: center;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}
</style>
