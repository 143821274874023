import Vue from "vue";
import ImageUpload from "@/views/ImageUpload.vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import Login from "@/views/Login";
import Register from "@/views/Register";
import PoseResult from "@/views/PoseResult";
import SchoolAdmin from "@/views/SchoolAdmin";
import Condition from "@/views/Condition";
import User from "@/views/User";
import Home from "@/views/Home";
import Admin from "@/views/Admin";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Upload",
        component: ImageUpload,
        meta: {
            title: "Upload",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login",
            authNotRequired: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            title: "Register",
            requiresAdmin: true,
            // authNotRequired: true
        },
    },
    {
        path: "/result",
        name: "Result",
        component: PoseResult,
        meta: {
            title: "Upload",
        },
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            title: "Home",
            authNotRequired: true,
        },
    },
    {
        path: "/school",
        name: "Result",
        component: SchoolAdmin,
        meta: {
            title: "Upload",
        },
    },
    {
        path: "/conditions",
        name: "Conditions",
        component: Condition,
        meta: {
            title: "Condition",
            requiresAdmin: true,
        },
    },
    {
        path: "/users",
        name: "Users",
        component: User,
        meta: {
            title: "Condition",
            requiresAdmin: true,
        },
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {
            title: "Admin",
            requiresAdmin: true,
        },
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | しせいの学校`;
    next();
});

router.beforeEach(async (to, from, next) => {
    
    let user = firebase.auth().currentUser;

    console.log('user', user)
    let admin = null;
    if (user) {
        let token = await user.getIdTokenResult();
        admin = token.claims.admin;
    }
    if (to.matched.some((res) => !res.meta.authNotRequired)) {
        if (user) {
            if (to.matched.some((res) => res.meta.requiresAdmin)) {
                if (admin) {
                    return next();
                }
                return next({ name: "Login" });
            }
            return next();
        }
        return next({ name: "Login" });
    }
    return next();
});

export default router;
