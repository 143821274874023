<template>
  <div class="sliderBody">
    <VueSlickCarousel
     
      v-bind="settings"
      style="display:flex; align-items:center; justify-content:center"
      :draggable="false"
    >
      <div  v-if="form.frontStandingImg">
        <PoseCanvasCopy
         
          :conditions="conditions"
          :image-src="images(form.frontStandingImg)"
          :poseDirectionData="frontStanding"
          @rendered="upload"
        />
      </div>
      <div  v-if="form.leftSideStandingImg">
        <PoseCanvasCopy
        v-if="form.leftSideStandingImg"
          :conditions="conditions"
          :image-src="images(form.leftSideStandingImg)"
          :poseDirectionData="leftSideStanding"
          @rendered="upload"
        />
      </div>
      <div   v-if=" form.rightSideStandingImg">
        <PoseCanvasCopy
        v-if=" form.rightSideStandingImg"
          :conditions="conditions"
          :image-src="images(form.rightSideStandingImg)"
          :poseDirectionData="rightSideStanding"
          @rendered="upload"
        />
      </div>
     
      <div  v-if="form.leftSideSittingImg">
        <PoseCanvasCopy
        v-if="form.leftSideSittingImg"
          :conditions="conditions"
          :image-src="images(form.leftSideSittingImg)"
          :poseDirectionData="leftSideSitting"
          @rendered="upload"
        />
      </div>
      <div  v-if="form.rightSideSittingImg">
        <PoseCanvasCopy
        v-if="form.rightSideSittingImg "
          :conditions="conditions"
          :image-src="images(form.rightSideSittingImg)"
          :poseDirectionData="rightSideSitting"
          @rendered="upload"
        />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import db from "@/firebase/firebaseInit";
import Vue from 'vue'
// import AnalyzedData from "@/components/AnalyzedData.vue"
// import PoseCanvas from "@/components/PoseCanvas.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PoseCanvasCopy from "@/components/PoseCanvasCopy.vue";

Vue.config.productionTip = false
export class PoseDirectionData {
  constructor(name, title, pose, direction) {
    this.name = name;
    this.title = title;
    this.pose = pose;
    this.direction = direction;
  }

  // frontStanding, leftSideStanding, rightSideStanding, leftSideSitting, rightSideSitting
  // nameは上記のいづれか
  name = "";
  title = "";
  ok = false; // 解析終了済みである
  messages = []; // 解析結果として表示するメッセージ
  analyzedData = []; // 解析結果
  direction = "";
  pose = "";
}

export default {
  components: {
    // AnalyzedData
    // PoseCanvas,
    VueSlickCarousel,
    PoseCanvasCopy
},
  data() {
    return {
      conditions: [],
      additionalData: {},
      sent: false,
      canAnalyze: true,
      frontStanding: new PoseDirectionData(
        "frontStanding",
        "立ち正面",
        "standing",
        "front"
      ),
      leftSideStanding: new PoseDirectionData(
        "leftSideStanding",
        "立ち左側面",
        "standing",
        "left"
      ),
      rightSideStanding: new PoseDirectionData(
        "rightSideStanding",
        "立ち右側面",
        "standing",
        "right"
      ),
      leftSideSitting: new PoseDirectionData(
        "leftSideSitting",
        "座り左側面",
        "sitting",
        "left"
      ),
      rightSideSitting: new PoseDirectionData(
        "rightSideSitting",
        "座り右側面",
        "sitting",
        "right"
      ),
      settings: {
        arrow: true,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  async mounted() {    
   
    
    if (!this.form.frontStandingImg) this.frontStanding.ok = true;
    if (!this.form.leftSideStandingImg) this.leftSideStanding.ok = true;
    if (!this.form.leftSideSittingImg) this.leftSideSitting.ok = true;
    if (!this.form.rightSideStandingImg) this.rightSideStanding.ok = true;
    if (!this.form.rightSideSittingImg) this.rightSideSitting.ok = true;
    console.log("this.frontStanding.ok", this.leftSideStandingIm);
    console.log("this.rightSideStanding.ok", this.rightSideStanding.ok);
    console.log("this.rightSideSitting.ok", this.rightSideSitting.ok);
    console.log("this.leftSideStanding.ok", this.leftSideStanding.ok);
    console.log("this.leftSideSitting.ok", this.leftSideSitting.ok);
    await this.getData();
    console.log("condition log =>")
   
  },
  computed: {

    form() {
      return this.$store.state.form;
    },
    analyzedAnglesData() {
      return this.$store.state.analyzeData;
    },
    finished() {
      return (
        this.frontStanding.ok &&
        this.rightSideStanding.ok &&
        this.rightSideSitting.ok &&
        this.leftSideStanding.ok &&
        this.leftSideSitting.ok
      );
    },
  },
  beforeDestroy(){
    localStorage.removeItem('isComponentCreated')
  },
  created(){
   const isComponentCreated = localStorage.getItem('isComponentCreated')
   if(isComponentCreated)
   {
        if (this.$route.name === "Result") {
        this.$router.push({path: '/'})
       
      }
      else
      {
        return
      }
    }
    else
    {
      localStorage.setItem('isComponentCreated', true);
   
      
   }
  },
  watch: {
    finished(finished) {
      if (!this.sent && finished) {
        console.log("this.additionalData", this.additionalData);
        console.log("state.form", this.$store.state.form);
        // const additionalData = JSON.parse(JSON.stringify(this.additionalData))
        // console.log(additionalData)
        const poseData = { ...this.additionalData, ...this.$store.state.form };
        console.log("poseData", poseData);
        this.$store.dispatch("uploadPoseData", poseData);
        this.sent = true;
      }
    },
  },
  methods: {
    images(file) {
      return URL.createObjectURL(file);
    },
    async getData() {
      const database = await db.collection("conditions").get();
      console.log("Condition data =>",database) 
      database.docs.forEach((doc) => {
        this.conditions.push(doc.data());
      });
    },
    // this function is to construct data to upload by each image
    async upload(files) {
      console.log("files.imageBlobURL", files.imageBlobURL);
      console.log("files.figureBlobURL", files.figureBlobURL);
      const imageBlobURL = await this.$store.dispatch(
        "uploadImage",
        files.imageBlob
      );
      const figureBlobURL = await this.$store.dispatch(
        "uploadImage",
        files.figureBlob
      );
      const result = {};
      const dp = files.data.direction + "_" + files.data.pose;
      result["imageBlobURL"] = imageBlobURL;
      result["figureBlobURL"] = figureBlobURL;
      result["angles"] = files.angles;
      if (dp === "front_standing") {
        result["pose"] = "立ち正面";
        this.additionalData[dp] = {
          result: result,
          msgs: files.messages.filter((msg) => msg.direction === "立ち正面"),
        };
      }
      if (dp === "left_standing") {
        result["pose"] = "立ち左側面";
        this.additionalData[dp] = {
          result: result,
          msgs: files.messages.filter((msg) => msg.direction === "立ち左側面"),
        };
      }
      if (dp === "right_standing") {
        result["pose"] = "立ち右側面";
        this.additionalData[dp] = {
          result: result,
          msgs: files.messages.filter((msg) => msg.direction === "立ち右側面"),
        };
      }
      if (dp === "left_sitting") {
        result["pose"] = "座り左側面";
        this.additionalData[dp] = {
          result: result,
          msgs: files.messages.filter((msg) => msg.direction === "座り左側面"),
        };
      }
      if (dp === "right_sitting") {
        result["pose"] = "座り右側面";
        this.additionalData[dp] = {
          result: result,
          msgs: files.messages.filter((msg) => msg.direction === "座り右側面"),
        };
      }

      files.data.ok = true;
      console.log("imageBlob", imageBlobURL);
      console.log("figureBlob", figureBlobURL);
    },
  },
};
</script>

<style>
.sliderBody {
display: flex;
flex-direction:column;
/* align-items: center; */
justify-content: center;
min-height: 100vh;
min-width: 100%;
}
.slick-prev{

  left: 10px;
  z-index: 200;
}
.slick-next{
  right: 20px;
  z-index: 200;
   
}
.slick-prev:before, .slick-next:before {

    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #ccc;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-slider{
  width: 100%;
  user-select:text !important;
}
.slick-list{
  min-width: 100%;
  width: 100%;
}


</style>
